import a from '@/utils/axios/axios'
import { v4 as uuidv4 } from 'uuid';

const team = {
  namespaced: true,
  state: () => ({
    template_name: '',
    items: [],
    mutable: true,
    clipboard: [],
    preventDeActivation: false,
    form_items: [],
    settings: {
      save_on_map: true,
      save_on_tag: false
    },
    rules: [],
    templates: [],
    id: null,
    loading: false
  }),
  mutations: {
    SET_STATE(state, new_state) {
      state = new_state
    },
    PUT_FORM_ITEM(state, item) {
      state.form_items = [...state.form_items].filter(t => t.id != item.id)

      state.form_items = [...state.form_items].concat([item])
    },
    ADD_TEMPLATE(state, template) {
      state.templates = [...state.templates].concat(template) 
    },
    SET_LOADING(state, val) {
      state.loading = val
    },
    REMOVE_TEMPLATE(state, id) {
      state.templates = [...state.templates].filter(t => t.id != id)
    },
    SET_ITEMS(state, items) {
      state.items = items
    },
    ADD_ITEM(state, item) {
      state.items = [...state.items].concat(item)
    },
    REMOVE_ITEM(state, item_id) {
      state.items = [...state.items].filter(item => item.id != item_id)
    },
    REMOVE_ACTIVE(state) {
      state.items = [...state.items].filter(item => !item.active)
    },
    PUT_ITEM(state, item) {
      state.items = [...state.items].filter(i => i.id != item.id)
      state.items = [...state.items].concat(item)
    },
    SET_CLIPBOARD(state, items) {
      state.clipboard = items
    },
    SET_PREVENT_DEACTIVATION(state, val){
      state.preventDeActivation = val
    },
    ADD_FORM_ITEM(state, items) {
      state.form_items = [...state.form_items].concat(items)
    },
    REMOVE_FORM_ITEM(state, id) {
      state.form_items = [...state.form_items].filter(t => t.id != id)
    },
    SET_SETTINGS(state, settings) {
      state.settings = settings
    },
    SET_TEMPLATE_NAME(state, name) {
      state.template_name = name
    },
    SET_RULES(state, rules) {
      state.rules = rules
    },
    ADD_RULE(state, rule) {
      state.rules = [...state.rules].concat({...rule, id: uuidv4()})
    },
    REMOVE_RULE(state, id) {
      state.rules = [...state.rules].filter(t => t.id != id)
    },
    SET_ID(state, id) {
      state.id = id
    },
    SET_TEMPLATES(state, templates) {
      state.templates = templates
    },
    INIT_TEMPLATE(state, template) {
      state.template_name = template.template_name
      state.items = template.items
      state.form_items = template.form_items
      state.rules = template.rules
      state.settings = {
        save_on_map: template.save_on_map_click,
        save_on_tag: template.save_on_tag_click
      }
      state.id = template.id
    },
    PUT_RULE(state, rule) {
      let mutable = [...state.rules].filter(t => t.id != rule.id)
      state.rules = mutable.concat(rule)
    }
  },
  actions: {
    initSportTemplates({ commit, rootGetters }, sport_id) {
      sport_id = sport_id || rootGetters['user/currentSportId']

      return new Promise((resolve, reject) => {
        a.get(`/template/sport/${sport_id}`)
          .then(e => {
            commit('SET_TEMPLATES', e.data)
            resolve(e.data)
          })
          .catch(e => reject(e))
      })
    },
    clearTemplate({ commit }) {
      commit('SET_ID', null)
      commit('SET_ITEMS', [])
      commit('SET_RULES', [])
      commit('SET_TEMPLATE_NAME', 'New template')
      commit('SET_SETTINGS', {
        save_on_map: false,
        save_on_tag: false
      })
    },
    initTeamTemplates({ commit, rootGetters }, {team_id, video_id}) {
      team_id = team_id || rootGetters['user/currentTeamId']
      commit('SET_LOADING', true)
      return new Promise((resolve, reject) => {
        a.get(`/template/team/${team_id}/video/${video_id}`)
          .then(e => {
            commit('SET_TEMPLATES', e.data)
            resolve(e.data)
          })
          .catch(e => reject(e))
          .finally(() => {
            commit('SET_LOADING', false)
          })
      })
    },
    initTemplate({ commit }, id) {
      return new Promise((resolve, reject) => {
        a.get(`/template/${id}`)
          .then(e => {
            commit('INIT_TEMPLATE', e.data)
            resolve(e.data)
          })
          .catch(e => reject(e))
      })
    },
    addItem({ commit }, item) {
      commit('ADD_ITEM', {
        ...item, 
        id: uuidv4()
      })
    },
    addFormItem({ commit }, item) {
      commit('ADD_FORM_ITEM', {
        ...item
      })
    },
    copyActive({ commit, getters }) {
      commit('SET_CLIPBOARD', getters.items.filter(t => t.active))
    },
    deleteTemplate({commit, dispatch}, id) {
      return new Promise((resolve, reject) => {
        a.delete(`/template/${id}`, id)
          .then(e => {
            resolve(e.data)
            commit('REMOVE_TEMPLATE', id)
            dispatch('clearTemplate')
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    pasteClipboard({ commit, getters }) {
      let addItems = getters.clipboard.map(c => {
        return {
          ...c,
          x: c.x + 10,
          y: c.y + 10,
          id: uuidv4()
        }
      })

      commit('ADD_ITEM', addItems)
      commit('SET_CLIPBOARD', addItems)
    },
    postSportButtonTemplate({ getters }, sport_id) {
      let body = {
        id: getters.id,
        template_name: getters.template_name, 
        save_on_map_click: getters.settings.save_on_map, 
        save_on_tag_click: getters.settings.save_on_tag, 
        immutable: true, 
        team_id: null, 
        sport_id, 
        rules: getters.rules, 
        form_items: getters.form_items, 
        items: getters.items
      }

      return new Promise((resolve, reject) => {
        a.post(`/template`, body)
          .then(e => {
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    postTeamButtonTemplate({ getters, rootGetters, commit }) {
      const team_id = rootGetters['user/currentTeamId']

      let body = {
        id: getters.id,
        template_name: getters.template_name, 
        save_on_map_click: getters.settings.save_on_map, 
        save_on_tag_click: getters.settings.save_on_tag, 
        immutable: true, 
        team_id,
        sport_id: null,
        rules: getters.rules, 
        form_items: getters.form_items, 
        items: getters.items
      }

      return new Promise((resolve, reject) => {
        a.post(`/template`, body)
          .then(e => {
            resolve(e.data)
            commit('SET_TEMPLATE', e.data)
            commit('ADD_TEMPLATE', e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    }
  },
  getters: {
    items: state => state.items,
    oneItemIsActive: (_, getters) => {
      return !!getters.items.find(t => t.active)
    },
    clipboard: state => state.clipboard,
    activeItem: (_, getters) => {
      return getters.items.find(i => i.active)
    },
    preventDeActivation: state => state.preventDeActivation,
    form_items: state => state.form_items,
    settings: state => state.settings,
    template_name: state => state.template_name,
    rules: state => state.rules,
    templates: state => state.templates,
    id: state => state.id,
    loading: state => state.loading
  }
}

export default team