const parse = require('parenthesis')
// import parse from 'parenthesis'

// TODO: sääntö jonka actionin kohteena nappi on ei pitäisi varmaan laueta

const handleChosenTagsCurrentClip = (chosenTags, rules, tagGroups, tagClicked) => {
  let tagsHolder = chosenTags

  rules.forEach(r => {
    if(r.active && isHandledNow(r.rule) && !hasHotkeyString(r.rule)) tagsHolder = evaluateRule(r.rule, tagsHolder, tagGroups, tagClicked)
  })

  return unique(tagsHolder)
}

const handleChosenTagsNextClip = (chosenTags, rules, tagGroups, tagClicked) => {
  let tagsHolder = chosenTags

  rules.forEach(r => {
    if(r.active && !isHandledNow(r.rule) && !hasHotkeyString(r.rule)) tagsHolder = evaluateRule(r.rule, tagsHolder, tagGroups, tagClicked)
  })

  return tagsHolder
}

const handleChosenTagsHotkey = (chosenTags, rules, tagGroups, tagClicked, hotkey) => {
  let tagsHolder = chosenTags

  rules.forEach(r => {
    if(r.active && isHandledHotkey(r.rule, hotkey)) tagsHolder = evaluateRule(r.rule, tagsHolder, tagGroups, tagClicked, hotkey)
  })

  return tagsHolder
}

const isHandledNow = rule => {
  return rule.indexOf('next_clip') < 0
}

const hasHotkeyString = rule => {
  return rule.indexOf('hotkey') > 0
}

const isHandledHotkey = (rule, hotkey) => {
  let hotkeyIndex = rule.indexOf('hotkey')

  if(hotkeyIndex < 0) return false

  let hotkeyInRule = rule.slice(hotkeyIndex + 6)

  if(!hotkey || !hotkeyInRule) return false
  return hotkeyInRule.trim() == hotkey.trim()
}









const evaluateRule = (rule, tags, tagGroups, tagClicked, hotkey) => {
  let isExecuted = evaluateIfExecuted(rule, tags, hotkey)
  let hasElse = hasString(rule, 'else')

  if(!isExecuted && !hasElse) return tags

  let newTags = processTags(rule, tags, tagGroups, isExecuted, tagClicked)

  return newTags
}

const evaluateIfExecuted = (rule, tags) => {
  let if_index = rule.indexOf('if')
  let then_index = rule.indexOf('then')

  if(if_index >= 0 && then_index < 0) {
    console.log('If statement present but then missing')
    return false
  }
  if(if_index < 0 && then_index < 0) return true

  let condition = rule.substring(if_index + 3, then_index)
  let parsed = parse(condition, {flat: true, escape: '&&'})

  return checkValidityIteration(parsed, undefined, 0, tags)
}

const checkValidityIteration = ( parsedArr, currentValue, index, tags ) => {
  let current = parsedArr[index]
  let hasOr = current.indexOf('or') > 0
  let hasAnd = current.indexOf('and') > 0

  if(hasOr && hasAnd) {
    console.log("Found 'or' and 'and' statement inside single parantheses")
    return false
  }

  let splitByAnds = current.split('and')
  let splitByOrs = current.split('or')

  let currentIsValid = currentValue

  if(hasAnd) {
    splitByAnds.forEach(phrase => {
      if(currentIsValid === undefined) currentIsValid = singlePhraseIsTrue(phrase, tags, currentIsValid, parsedArr)
      else currentIsValid = currentIsValid && singlePhraseIsTrue(phrase, tags, currentIsValid, parsedArr)
    })
  } else if(hasOr) {
    splitByOrs.forEach(phrase => {
      if(currentIsValid === undefined) currentIsValid = singlePhraseIsTrue(phrase, tags, currentIsValid, parsedArr)
      else currentIsValid = currentIsValid || singlePhraseIsTrue(phrase, tags, currentIsValid, parsedArr)    })
  } else {
    currentIsValid = singlePhraseIsTrue(current, tags, currentIsValid, parsedArr)
  }

  return currentIsValid
}

const singlePhraseIsTrue = (phrase, tags, currentIsValid, parsedArr) => {
  if(phrase.indexOf('&&') >= 0) {
    let result = checkValidityIteration( parsedArr, undefined, findEscapeFromPhrase(phrase), tags )
    return result
  }

  let isValid;

  if(phrase.indexOf('is chosen') >= 0) {
    let [ tag ] = phrase.split('is chosen')
    let tagId = Number(tag.replace('tag', '').trim())

    isValid = tagIsChosen(tagId, tags)
  }

  if(phrase.indexOf('not chosen') >= 0) {
    let [ tag ] = phrase.split('not chosen')
    let tagId = Number(tag.replace('tag', '').trim())

    isValid = !tagIsChosen(tagId, tags)
  }

  return isValid || false
}

const tagIsChosen = (id, tags) => {
  return tags.findIndex(t => t.id == id) >= 0
}

const findEscapeFromPhrase = phrase => {
  if(phrase.indexOf('&&') < 0) return -1

  let arr = phrase.split('&&')
  let num = -1

  arr.forEach((p) => {
    if( !isNaN(Number(p)) ) num = p
  })

  return num
}

const processTags = (rule, tags, tagGroups, mainAction, tagClicked) => {
  let action_rule;
  if(mainAction) {
    action_rule = parseActionFromRule(rule)
  } else {
    action_rule = parseActionFromElseRule(rule)
  }

  const actions = action_rule.split('and')
  let processed_tags = tags

  actions.forEach(action => {
    processed_tags = processSingleAction(action, processed_tags, tagGroups, tagClicked)
  })

  return processed_tags
}

const processSingleAction = (action, tags, tagGroups, tagClicked) => {
  if(hasString(action, 'choose')) {
    action = action.replace('choose', '')
    if(hasString(action, 'tag')) {
      const tag_id = Number(action.replace('tag', '').trim())
      if(!tagClicked || tagClicked.id != tag_id) tags = addTagById(tags, tagGroups, tag_id)
    }
  }

  if(hasString(action, 'clear')) {
    action = action.replace('clear', '')
    if(hasString(action, 'tag')) {
      const tag_id = Number(action.replace('tag', '').trim())
      if(!tagClicked || tagClicked.id != tag_id) tags = tags.filter(t => t.id != tag_id)
    }
    if(hasString(action, 'group')) {
      const group_id = Number(action.replace('group', '').trim())
      if(!tagClicked || tagClicked.group_id != group_id) tags = tags.filter(t => t.group_id != group_id)
    }
  }

  if(hasString(action, 'cycle_forward')) {
    action = action.replace('cycle_forward', '')
    if(hasString(action, 'group')) {
      const group_id = Number(action.replace('group', '').trim())

      if(!tagClicked || tagClicked.group_id != group_id) tags = cycleGroupForward(group_id, tags, tagGroups)
    }
  }

  if(hasString(action, 'cycle_backwards')) {
    action = action.replace('cycle_backwards', '')
    if(hasString(action, 'group')) {
      const group_id = Number(action.replace('group', '').trim())

      if(!tagClicked || tagClicked.group_id != group_id) tags = cycleGroupBackward(group_id, tags, tagGroups)
    }
  }

  if(hasString(action, 'copy')) {
    action = action.replace('copy', '')
    action = action.replace('group', '').trim()
    action = action.replace('group', '').trim()

    const group_from_id = Number(action.split('to')[0].trim())
    const group_to_id = Number(action.split('to')[1].trim())

    if(!tagClicked || tagClicked.group_id != group_to_id) tags = copyTagFromGroupByName(group_from_id, group_to_id, tags, tagGroups)
  }

  if(hasString(action, 'switch')) { // switch group 4 with group 7
    action = action.replace('switch', '')
    action = action.replace('group', '').trim()
    action = action.replace('group', '').trim()

    const group_1_id = Number(action.split('with')[0].trim())
    const group_2_id = Number(action.split('with')[1].trim())

    tags = switchGroups(group_1_id, group_2_id, tags, tagGroups)
  }

  return tags
}

const copyTagFromGroupByName = (from_id, to_id, chosen, groups) => {
  let from_group = groups.find(g => g.id == from_id)
  let to_group = groups.find(g => g.id == to_id)

  if(!from_group || !to_group) return chosen
  let newTags = []
  chosen.forEach(tag => {
    if(from_group.tags.findIndex(t => t.id == tag.id) >= 0) {
      let toByName = to_group.tags.find(to => to.tag_name == tag.tag_name)
      newTags.push(toByName)
    }
  })

  return chosen.concat(newTags)
}

const switchGroups = (id1, id2, tags, tagGroups) => {
  let chosen1 = tags.filter(t => t.group_id == id1)
  let chosen2 = tags.filter(t => t.group_id == id2)

  // filter current ones out
  tags = tags.filter(t => t.group_id != id1 && t.group_id != id2)

  let group1Tags = tagGroups.find(g => g.id == id1)?.tags
  let group2Tags = tagGroups.find(g => g.id == id2)?.tags

  let newTags1 = []
  let newTags2 = []
  
  if(group1Tags) newTags1 = group1Tags.filter(t => chosen2.map(t => t.tag_name).includes(t.tag_name))
  if(group2Tags) newTags2 = group2Tags.filter(t => chosen1.map(t => t.tag_name).includes(t.tag_name))

  return tags.concat(newTags1).concat(newTags2)
}

const cycleGroupForward = (group_id, tags, tagGroups) => {
  const group = tagGroups.find(g => g.id == group_id)
  if(!group) return tags
  
  const chosenGroupTags = tags.filter(t => t.group_id == group_id)
  if(!chosenGroupTags.length == 1) return tags
  const chosenTagPos = chosenGroupTags[0].position

  let newTag = group.tags.find(t => t.position == chosenTagPos + 1)
  if(!newTag) newTag = group.tags.find(t => t.position == 0)

  tags = tags.filter(t => t.group_id != group_id)
  if(newTag) tags.push(newTag)

  return tags
}

const cycleGroupBackward = (group_id, tags, tagGroups) => {
  const group = tagGroups.find(g => g.id == group_id)
  if(!group) return tags
  
  const chosenGroupTags = tags.filter(t => t.group_id == group_id)
  if(!chosenGroupTags.length == 1) return tags
  const chosenTagPos = chosenGroupTags[0].position

  let newTag = group.tags.find(t => t.position == chosenTagPos - 1)
  if(!newTag) {
    let maxPos = 0
    group.tags.forEach(t => {
      if(maxPos < t.position) maxPos = t.position
    })

    newTag = group.tags.find(t => t.position == maxPos)
  }

  tags = tags.filter(t => t.group_id != group_id)
  if(newTag) tags.push(newTag)

  return tags
}

const addTagById = (tags, tagGroups, tag_id) => {
  let flatTags = tagGroups.map(group => group.tags)
  flatTags = flatTags.flat()

  let tag = flatTags.find(tag => tag.id == tag_id)

  if(!tag) return tags

  tags.push(tag)

  return tags
}

const hasString = (string, piece) => {
  return string.indexOf(piece) >= 0
}

const parseActionFromRule = rule => {
  let then_index = rule.indexOf('then')
  let else_index = rule.indexOf('else')
  let when_index = rule.indexOf('when')

  let condition

  // no then and when
  if(then_index >= 0 && else_index >= 0) condition = rule.substring(then_index + 5, else_index)
  // then and when
  else if(then_index >= 0 && when_index >= 0) condition = rule.substring(then_index + 5, when_index)
  // then and no when
  else if(then_index >= 0 && when_index < 0) condition = rule.slice(then_index + 5)
  // no then and when
  else if(then_index < 0 && when_index >= 0) condition = rule.substring(0, when_index)

  else condition = rule

  return condition
}

const parseActionFromElseRule = rule => {
  let then_index = rule.indexOf('else')
  let when_index = rule.indexOf('when')

  let condition
  if(then_index >= 0 && when_index >= 0) condition = rule.substring(then_index + 5, when_index)
  else if(then_index >= 0 && when_index < 0) condition = rule.slice(then_index + 5)
  else if(then_index < 0 && when_index >= 0) condition = rule.substring(0, when_index)
  else condition = rule

  return condition
}

const unique = (data) => {
  return [...data.reduce((a,c)=>{
    a.set(c.id, c);
    return a;
  }, new Map()).values()];
}

module.exports = { handleChosenTagsHotkey, handleChosenTagsCurrentClip, handleChosenTagsNextClip }