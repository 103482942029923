import a from '@/utils/axios/axios'

const time = {
  namespaced: true,
  state: () => ({
    timenames: [],
    timenamesLoading: false,
    title: '',
    data: [],
    firstTime: 0,
    times: [],
    totalTimes: 0,
    averages: [],
    columns: [],
    filterByTags: []
  }),
  mutations: {
    SET_LOADING_TIMENAMES(state, payload) {
      state.timenamesLoading = payload
    },
    SET_TIMENAMES(state, payload) {
      state.timenames = payload
    },
    ADD_TIMENAME(state, payload) {
      state.timenames = [...state.timenames].concat(payload)
    },
    PUT_TIMENAME(state, payload) {
      let i = state.timenames.findIndex(t => t.id == payload.id)
      let mod = [...state.timenames]
      mod[i] = payload
      state.timenames = mod
    },
    REMOVE_DATA(state, id) {
      state.data = [...state.data].filter(d => d.timename_id != id)
    },
    ADD_DATA(state, payload) {
      state.data = [...state.data].concat(payload)
    },
    SET_FIRSTTIME(state, payload) {
      state.firstTime = payload
    },
    REMOVE_ALL_DATA(state) {
      state.data = []
      state.firstTime = 0
      state.title = ''
    },
    SET_TIMES(state, paylaod) {
      state.times = paylaod
    },
    SET_TOTAL_TIMES(state, payload) {
      state.totalTimes = payload
    },
    SET_AVERAGES(state, averages) {
      state.averages = averages
    },
    SET_COLUMNS(state, columns) {
      state.columns = columns
    },
    SET_FILTER_TAG(state, tags) {
      state.filterByTags = tags
    }
  },
  actions: {
    timenameOrder({ commit, rootGetters }, payload) {
      const teamId = rootGetters['user/currentTeamId']
      commit('SET_TIMENAMES', payload)
      return new Promise((resolve, reject) => {
        a.put('/time/timename/order', { timenames: payload, team_id: teamId })
          .then(e => {
            resolve(e)
          })
          .catch(e => reject(e))
      })
    },
    initTeamnames({ commit, rootGetters }) {
      const teamId = rootGetters['user/currentTeamId']
      commit('SET_LOADING_TIMENAMES', true)
      return new Promise((resolve, reject) => {
        a.get(`/time/timename/team/${teamId}`)
          .then(res => {
            commit('SET_COLUMNS', res.data.map(e => e.name) )
            commit('SET_TIMENAMES', res.data)
            resolve(res)
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
          .finally(() => {
            commit('SET_LOADING_TIMENAMES', false)
          })
      })
      },
      getTeamTimes({ commit, rootGetters }, { page, itemsPerPage, sortBy, sortDesc, columns, tags }) {
        if(!columns?.length) return []
        const teamId = rootGetters['user/currentTeamId']
        return new Promise((resolve, reject) => {
          a.get(`/time/team/${teamId}?page=${page}&columns=${columns}&sortDesc=${sortDesc}&sortBy=${sortBy}&itemsPerPage=${itemsPerPage}&tags=${tags}`)
            .then(e => {
              commit('SET_TIMES', e.data.times)
              commit('SET_TOTAL_TIMES', e.data.total)
              commit('SET_AVERAGES', e.data.avgs)
              resolve(e)
            })
            .catch(e => {
              reject(e)
            })
        })
      },
      deleteTime({ commit }, id) {
        return new Promise((resolve, reject) => {
          a.delete(`/time/${id}`)
            .then(res => {
              commit('videos/removeTime', id, { root: true })
              resolve(res)
            })
            .catch(err => {
              reject(err)
            })
        })
      },
      addTime({ getters }, { title, video_id, tag_ids }) {
        const data = {
          title: title,
          video_id: video_id,
          timenames: [...getters.data],
          tag_ids
        }

        return new Promise((resolve, reject) => {
          a.post(`/time`, data)
            .then(res => {
              resolve(res.data)
            })
            .catch(err => {
              reject(err)
            })
            .finally(() => {
              
            })
        })
      },
      updateTimename({ commit }, timename) {
        return new Promise((resolve, reject) => {
          a.put(`/time/timename`, timename)
            .then(res => {
              commit('PUT_TIMENAME', res.data)
              resolve(res.data)
            })
            .catch(err => {
              reject(err)
            })
            .finally(() => {
              
            })
        })
      },
      editTimeTags({ commit }, { id, tags }) {
        return new Promise((resolve, reject) => {
          a.put(`/time/${id}/tag`, {tags})
            .then(res => {
              resolve(res.data)
              commit('videos/updateVideoTime', res.data, { root: true })
            })
            .catch(err => {
              reject(err)
            })
            .finally(() => {
              
            })
        })
      },
      editTimeTitle({commit}, {id, title}) {
        return new Promise((resolve, reject) => {
          a.put(`/time/${id}/title`, {title})
            .then(res => {
              resolve(res.data)
              commit('videos/updateVideoTime', res.data, { root: true })
            })
            .catch(err => {
              reject(err)
            })
        })
      },
      addTimename({ commit }, payload) {
        return new Promise((resolve, reject) => {
          a.post(`/time/timename/`, payload)
            .then(res => {
              commit('ADD_TIMENAME', res.data)
              resolve(res.data)
            })
            .catch(err => {
              reject(err)
            })
        })
      },
      clearTime({ commit }) {
        commit('REMOVE_ALL_DATA')
      },
      timenameClicked({ getters, commit, rootGetters }, timename) {
        
        if( getters.dataIds.includes(timename.id) ) {
          commit('REMOVE_DATA', timename.id)
          return
        }
        
        const currentTime = rootGetters['player/currentTime']
        if(getters.dataIds.length === 0) commit('SET_FIRSTTIME', currentTime)

        const time_from_first = getters.dataIds.length === 0
          ? 0 : currentTime - getters.firstTime

        commit('ADD_DATA', {
          timename_id: timename.id,
          timename_name: timename.name,
          video_time: currentTime,
          time_from_first
        })

      },
      timeById(_, id) {
        return new Promise((resolve, reject) => {
          a.get(`/time/${id}`)
            .then(e => {
              resolve(e)
            })
            .catch(e => {
              reject(e)
            })
        })
      }
  },
  getters: {
    timenames: state => state.timenames,
    allTimenameNamesOnly: (state) => {
      return [...state.timenames].map(tn => {
        return tn.name
        // return {
        //   ...tn,
        //   chosen: getters.dataIncludesId(tn.id),
        //   time: getters.timenameSetTime(tn.id),
        //   average: getters.averages.find(a => a.name == tn.name)?.average
        // }
      })
    },
    timenameOrdered: (state, getters) => {
      const mapped = getters.timenames.map(tn => {
        return {
          ...tn,
          chosen: getters.dataIncludesId(tn.id),
          time: getters.timenameSetTime(tn.id),
          average: getters.averages.find(a => a.name == tn.name)?.average
        }
      })

      // better ux if the times dont jump around
      // mapped.sort((a, b) => {
      //   if(a.chosen && !b.chosen) return -1
      //   if(!a.chosen && b.chosen) return 1
      //   return a.average - b.average
      // })

      return mapped
    },
    times: state => state.times,
    totalTimes: state => state.totalTimes,
    timenamesLoading: state => state.timenamesLoading,
    data: state => state.data,
    dataIds: state => state.data.map(d => d.timename_id),
    firstTime: state => state.firstTime,
    lastTimeFromFirst: (state, getters) => {
      let last;
      getters.data.forEach(d => {
        if(!last || d.time_from_first > last) last = d.time_from_first
      })

      return last || 0
    },
    dataIncludesId: (state, getters) => id => {
      return getters.dataIds.includes(id)
    },
    timenameSetTime: (state) => id => {
      const timename = state.data.find(d => d.timename_id == id)
      if(!timename) return

      return timename.time_from_first
    },
    averages: state => state.averages,
    columns: state => state.columns,
    filterByTags: state => state.filterByTags
  }
}

export default time