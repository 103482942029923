import a from '@/utils/axios/axios'
// import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';

const draw = {
  namespaced: true,
  state: () => ({
    editor_cont_item: document.querySelector(".editor-cont-main")?.offsetWidth,
    objects: [], // Every graphic object
    layers: [], // All graphics layers including slowdowns
    layer_id: null, // Id of the graphics layer that is being modified
    new_color: "#000000", // Color picker color (stroke color)
    new_stroke_width: 5,
    new_fill_enabled: false,
    new_fill_color: "#000000",
    new_opacity: 1,
    new_font_size: 40,
    new_text: 'New text',
    new_style: 'full', // the style of drawn object, dash or full
    scale: 1,
    canvas_dimensions_key: 0,
    clip: null, // The clip that's playing - this is passed on to Player component
    dont_clear: false, // Don't ask :)
    chosen_object: null, // the chosen draw button - arrow, line, text, ellipse, null
    selected_shape_name: null, // the uuid identifier of the focused shape
    creating_object_name: null, // when screen dragged  the identifier of the shape created is here
    colors: [ // colors for the color picker
      // First Nested List
      [
        "#000000",  // Black
        "#27AE60",  // Green
        "#FF6B6B",  // Coral
        "#3498DB",  // Blue
        "#F39C12",  // Yellow
      ],
      // Second Nested List
      [
        "#FFFFFF",  // White
        "#9B59B6",  // Purple
        "#E74C3C",  // Red
        "#1ABC9C",  // Turquoise
        "#F1C40F",  // Gold
      ], 
      // Third Nested List
      [
        "#2ECC71",  // Emerald
        "#8E44AD",  // Violet
        "#E67E22",  // Orange
        "#2980B9",  // Ocean Blue
        "#D35400"   // Pumpkin
      ],
    ],
    show_editor_settings: false,
    original_video_width: null,
    options: null, // player options
    pause_timeout: null, // timeout that handles pausing on the graphics
  }),
  mutations: {
    CLEAR(state) {
      state.objects = []
      state.layers = []
    },
    SET_SCALE(state, scale) {
      state.scale = scale
    },
    UPDATE_SCALE(state) {
      state.canvas_dimensions_key++
    },
    REFRESH_EDITOR_CONT_ITEM_WIDTH(state, width) {
      state.editor_cont_item = width || document.querySelector(".editor-cont-main")?.offsetWidth
    },
    SET_LAYERS(state, layers) { state.layers = layers },
    ADD_LAYER(state, layer) {
      state.layers = [...state.layers].concat(layer)
    },
    REMOVE_LAYER_BY_ID(state, id) {
      state.layers = [...state.layers].filter(i => i.id !== id)
    },
    SET_OBJECTS(state, objects) { 
      state.objects = objects 
    },
    EDIT_OBJECT(state, object) {
      if(!object) return
      state.objects = [...state.objects].filter(v => v.name !== object.name).concat(object)
    },
    ADD_OBJECT(state, object) {
      state.objects = [...state.objects].concat(object)
    },
    REMOVE_OBJECT(state, name) { 
      state.objects = [...state.objects].filter(o => o.name !== name)
    },
    REMOVE_LAYER_OBJECTS_BY_ID(state, layer_id) {
      state.objects = [...state.objects].filter(o => o.layer_id !== layer_id)
    },
    SET_CLIP(state, clip) { state.clip = clip },
    SET_OPTIONS(state, options) { state.options = options },  
    SET_NEW_COLOR(state, color) { state.new_color = color },   
    SET_SELECTED_SHAPE_NAME(state, name) { state.selected_shape_name = name },
    SET_CHOSEN_OBJECT(state, id) { 
      state.chosen_object = id
      state.selected_shape_name = ''
    },
    SET_ORIGINAL_VIDEO_WIDTH(state, width) { state.original_video_width = width },
    SET_PAUSE_TIMEOUT(state, timeout) { state.pause_timeout = timeout },
    SET_LAYER_ID(state, layer_id) { state.layer_id = layer_id },

    SET_NEW_STROKE_WIDTH(state, value) { state.new_stroke_width = value },
    SET_NEW_TEXT(state, text) { state.new_text = text },
    SET_NEW_FILL_ENABLED(state, enabled) { state.new_fill_enabled = enabled },
    SET_NEW_FONT_SIZE(state, size) { state.new_font_size = size },
    SET_DONT_CLEAR(state, value) { state.dont_clear = value },
    SET_NEW_STYLE(state, value) { state.new_style = value}
  },
  actions: {
    initGraphics({ commit }, payload) {
      commit('SET_LAYERS', payload.layers)
      commit('SET_OBJECTS', payload.objects)
      commit('SET_ORIGINAL_VIDEO_WIDTH', payload.original_video_width)
    },
    updateScale({ commit }){
      Vue.nextTick(() => {
        commit("UPDATE_SCALE")
        commit("REFRESH_EDITOR_CONT_ITEM_WIDTH")
      })
    },
    goToPoint() {

    },
    changeShapeColor({ getters, commit }, color) {
      const name = getters.selected_shape_name;
      if(!name) return

      const ob = getters.object_by_id(name)
      commit('EDIT_OBJECT', { ...ob, stroke: color })
    },
    removeObject({ getters, commit}, object_name) {
      if(getters.objects.filter(o => o.layer_id == getters.layer_id).length ==  1) {
        commit('REMOVE_LAYER_BY_ID', getters.layer_id)
      } 
  
      commit('REMOVE_OBJECT', object_name)

    },
    removePauseTimeout({ getters, commit }) {
      clearTimeout(getters.pause_timeout)
      commit('SET_PAUSE_TIMEOUT', null)
    },
    setPauseTimeout({ commit, dispatch, getters, rootGetters }, seconds) {
      clearTimeout(getters.pause_timeout)

      const timeout = setTimeout(
        () => {
          if (rootGetters['player/player']) dispatch('player/play', {}, { root: true })
      }, seconds * 1000)

      commit('SET_PAUSE_TIMEOUT', timeout)
    },
    layerClicked({ getters, commit, dispatch }, { id, start_again }) {
      let item = getters.layers.find(i => i.id == id)

      commit('SET_DONT_CLEAR', true)

      dispatch('player/pause', null, { root: true })
      dispatch('player/seek', item?.starttime, { root: true })

      commit('SET_CHOSEN_OBJECT', null)
 
      Vue.nextTick(() => {
        setTimeout(() => {
          commit('SET_DONT_CLEAR', false)
        }, 200)

        commit('SET_LAYER_ID', id)
      })
      
      if(!start_again || !item.duration) return
      
      dispatch('setPauseTimeout', item.duration)
    },
    putClipGraphics({ getters }) {
      let clip_id = getters.clip.id
      const graphics = {
        objects: getters.objects,
        layers: getters.layers,
        original_video_width: getters.original_video_width ? getters.original_video_width : getters.player_container_size.width
      }

      return new Promise((resolve, reject) => {
        a.put(`/clip/${clip_id}/graphics`, { graphics })
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            
          })
      })
    },
    clear({commit}) {
      commit('CLEAR');
    },
  },
  getters: {
    // STATE
    objects: state => state.objects,
    layers: state => state.layers,
    layer_id: state => state.layer_id,
    new_color: state => state.new_color,
    clip: state => state.clip,
    dont_clear: state => state.dont_clear,
    is_dragging: state => state.is_dragging,
    chosen_object: state => state.chosen_object,
    selected_shape_name: state => state.selected_shape_name,
    creating_object_name: state => state.creating_object_name,
    colors: state => state.colors,
    show_editor_settings: state => state.show_editor_settings,
    options: state => state.options,
    original_video_width: state => state.original_video_width,
    pause_timeout: state => state.pause_timeout,
    new_stroke_width: state => state.new_stroke_width,
    new_text: state => state.new_text,  
    new_font_size: state => state.new_font_size,
    new_style: state => state.new_style,
    new_fill_enabled: state => state.new_fill_enabled,
    editor_cont_item: state => state.editor_cont_item,
    scale: state => state.scale,
    canvas_dimensions_key: state => state.canvas_dimensions_key,

    // MODIFIED
    selected_shape: (state, getters) => {
      return getters.objects.find(ob => ob.name == getters.selected_shape_name) || {}
    },
    handled_layers: (state, getters) => {
      // adds the width and left-margin to layer items
      getters.canvas_dimensions_key;

      if(!getters.options) return []
      
      return getters.layers?.map(item => {
        return {
          ...item,
          width: getters.form_item_width(item),
          left: getters.form_item_left(item)
        }
      }) || []
    },

    // HELPER FUNCTIONS
    form_item_width: (_, getters) => ({ starttime, endtime, still }) => {
      getters.options;
      
      if(still) return 35
      const w = getters.editor_cont_item
      if(!w) return 0
      return (endtime - starttime) / (getters.options.endtime - getters.options.starttime) * w
    },
    form_item_left: (_, getters) => ({ starttime, wi }) => {
      getters.options;
      getters.canvas_dimensions_key;
      
      const w = wi || getters.editor_cont_item
      if(!w) return 0

      return (( starttime - getters.options.starttime ) / (getters.options.endtime - getters.options.starttime) ) * w + 15 + 2
    },
    object_by_id: (_, getters) => name => {
      return getters.objects.find(v => v.name == name)
    },
    player_container_size: (_, getters) => {
      getters.canvas_dimensions_key;

      const element = document.getElementById('io-player')
      let width = element.offsetWidth;
      let height = element.offsetHeight;

      return {
        height,
        width
      }

      // if(wi > he) {
      //   he = he - 260
      //   return {
      //     width: he * 16 / 9,
      //     height: he
      //   }
      // } else {
      //   return {
      //     width: wi,
      //     height: wi * 9 / 16
      //   }
      // }
    }
  }
}

export default draw