// import a from '@/utils/axios/axios'

const game = {
  namespaced: true,
  state: () => ({
    lineups: null,
    game_players: []
  }),
  mutations: {
    SET_LINEUPS(state, val) {
      state.lineups = val
    },
    SET_GAME_PLAYERS(state, val) {
      state.game_players = val
    }
  },
  actions: {

  },
  getters: {
    lineups: state => state.lineups,
    game_players: state => state.game_players,
    players_by_club_id: state => id => {
      if(!id) return []
      return state.game_players.filter(p =>{
        return p.league_club_id === id
      }).sort((a, b) => { return a.num - b.num })
    }
  }
}

export default game