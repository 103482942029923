<template>
  <div>
    <v-snackbar
      :value="snackBar"
      :color="snackbarColor"
      rounded="pill"
      right
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="buttonColor"
          text
          v-bind="attrs"
          @click="hide()"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters('noti', [
      'snackBar'
    ]),
    snackbarText() {
      if(this.snackBar) return this.snackBar.text
      return ''
    },
    buttonColor() {
      return 'white'
    },
    snackbarColor() {
      if(!this.snackBar) return 'primary'
      switch(this.snackBar.type) {
        case 'success':
          return 'green darken-3'
        case 'error':
          return 'red'
        case 'info':
          return 'primary'
        default:
          return 'black'
      }
    }
  },
  methods: {
    ...mapActions('noti', [
      'hideSnackbar'
    ]),
    hide() {
      this.hideSnackbar()
    }
  }
}
</script>