// import a from '@/utils/axios/axios'

const noti = {
  namespaced: true,
  state: () => ({
    notification: null,
    snackBar: null,
    timeout: null
  }),
  mutations: {
    setSnackbar(state, notification) {
      state.snackBar = notification
    },
    clearTimeout(state) {
      clearTimeout(state.timeout)
    }
  },
  actions: {
    showSnackbar({ commit }, { text, type, duration }) { // error, success, info
      if(!duration) duration = 5
      commit('setSnackbar', { text, type })
      commit('clearTimeout')
      setTimeout(() => {
        commit('setSnackbar', null)
      }, duration * 1000)
    },
    hideSnackbar({ commit }) {
      commit('setSnackbar', null)
    },
    error({ dispatch }, error) {
      dispatch('showSnackbar', {
        text: error?.response?.data || 'Something went wrong :(',
        type: 'error',
        duration: 5
      })
      // TODO only in dev
    },
    success({ dispatch }, text) {
      dispatch('showSnackbar', {
        text: text,
        type: 'success',
        duration: 100
      })
    },
    info({ dispatch }, text) {
      dispatch('showSnackbar', {
        text: text,
        type: 'info',
        duration: 100
      })
    }
   },
  getters: {
    snackBar: state => state.snackBar,
  }
}

export default noti