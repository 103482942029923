import Vue from 'vue'
import Vuex from 'vuex'
import videos from '@/store/modules/videos.js'
import auth from '@/store/modules/auth.js'
import user from '@/store/modules/user.js'
import noti from '@/store/modules/noti.js'
import player from '@/store/modules/player.js'
import team from '@/store/modules/team.js'
import clip from '@/store/modules/clip.js'
import tag from '@/store/modules/tag.js'
import folder from '@/store/modules/folder.js'
import time from '@/store/modules/time.js'
import filter from '@/store/modules/filter.js'
import rule from '@/store/modules/rule.js'
import map from '@/store/modules/map.js'
import admin from '@/store/modules/admin.js'
import ticks from '@/store/modules/ticks.js'
import text_file from '@/store/modules/text_file.js'
import template from '@/store/modules/template.js'
import tagging from '@/store/modules/tagging.js'
import presentation from '@/store/modules/presentation.js'
import draw from '@/store/modules/draw.js'
import share from '@/store/modules/share.js'
import timeline from '@/store/modules/timeline.js'
import comment from '@/store/modules/comment.js'
import batch from '@/store/modules/batch.js'
import data from '@/store/modules/data.js'
import streaming from '@/store/modules/streaming.js'
import exp from '@/store/modules/export.js'
import upload from '@/store/modules/upload.js'
import file from '@/store/modules/file.js'
import game from '@/store/modules/game.js'

import a from '@/utils/axios/axios'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: false,
  state: {
    // Is the lefthand sidebar mini or not
    show_sidebar: false,
    // user data is stored in root

    sports: [],
    keybinds: true,
    showMobileNavBar: true,
  },
  mutations: {
    //  Toggles sidebars width
    toggleSideBar(state, value) {
      if(value === undefined) state.show_sidebar = !state.show_sidebar
      else state.show_sidebar = value
    },
    TOGGLE_MOBILE_NAVBAR(state, value) {
      state.showMobileNavBar = value;
    },
    SET_SPORTS(state, sports) {
      state.sports = sports
    },
    ENABLE_KEYBINDS(state) {
      state.keybinds = true
    },
    DISABLE_KEYBINDS(state) {
      state.keybinds = false
    },
  },
  actions: {
    initSports({ commit }) {
      return new Promise((resolve, reject) => {
        a.get('/sport')
          .then(e => {
            commit('SET_SPORTS', e.data)
            resolve(e)
          })
          .catch(e => reject(e))
      })
    },
    deleteUser(_, email) {
      return new Promise((resolve, reject) => {
        a.post('/auth/request-delete', { email })
          .then(e => {
            resolve(e)
          })
          .catch(e => reject(e))
      })
    },
    deleteTeam(_, team_id) {
      return new Promise((resolve, reject) => {
        a.post('/auth/request-team-delete', { team_id })
          .then(e => {
            resolve(e)
          })
          .catch(e => reject(e))
      })
    }
  },
  getters: {
    showMobileNavBar: state => state.showMobileNavBar,
    show_sidebar: state => state.show_sidebar,
    sports: state => state.sports,
    keybinds: state => state.keybinds,
    mappedSports: (state, getters) => {
      const mapped = getters.sports.map(s => {
        return {
          value: s.id,
          text: s.name
        }
      })

      return [{
        value: null,
        text: 'Choose your sport'
      }].concat(mapped)
    },
    isApp: () => {
      let standalone = window.navigator.standalone
      let userAgent = window.navigator.userAgent.toLowerCase()
      let safari = /safari/.test(userAgent)
      let ios = /iphone|ipod|ipad/.test(userAgent)

      if (ios) {
        if (!standalone && safari) {
          // Safari
          return false
        } else if (!standalone && !safari) {
          // iOS webview
          return true
        }
      } else {
        if (userAgent.includes('wv')) {
          return true
          // Android webview
        } else {
          return false
          // Chrome
        }
      }

      return false
    },
    isIos: () => {
      let standalone = window.navigator.standalone
      let userAgent = window.navigator.userAgent.toLowerCase()
      let safari = /safari/.test(userAgent)
      let ios = /iphone|ipod|ipad/.test(userAgent)

      if (ios) {
        if (!standalone && safari) {
          // Safari
          return false
        } else if (!standalone && !safari) {
          // iOS webview
          return true
        }
      }

      return false
    },
    isAndroid: () => {
      let userAgent = window.navigator.userAgent.toLowerCase()
      let ios = /iphone|ipod|ipad/.test(userAgent)

      if (ios) {
        return false
      } else {
        if (userAgent.includes('wv')) {
          return true
          // Android webview
        } else {
          return false
          // Chrome
        }
      }
    }
  },
  modules: {
    videos,
    auth,
    user,
    noti,
    player,
    team,
    clip,
    tag,
    folder,
    time,
    filter,
    rule,
    map,
    admin,
    ticks,
    text_file,
    template,
    tagging,
    presentation,
    draw,
    share,
    timeline,
    comment,
    batch,
    data,
    streaming,
    export: exp,
    upload,
    file,
    game
  }
})
