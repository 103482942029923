const comment = {
  namespaced: true,
  state: () => ({
    commentVisible: false,
    comment: null,
  }),
  mutations: {
    TOGGLE_COMMENT(state) {
      state.commentVisible = !state.commentVisible;
    },
    SET_COMMENT(state, payload) {
      state.comment = payload;
    },
    RESET_COMMENT(state) {
      state.comment = null;
      state.commentVisible = false;
    },
  },
  actions: {
    /**
     * Toggles the comment hidden or seen.
     *
     * @param {*} commit vuex context
     */
    toggleComment({ commit }) {
      commit("TOGGLE_COMMENT");
    },
    setComment({ commit }, payload) {
      commit("SET_COMMENT", payload);
    },
    resetComment({ commit }) {
      commit("RESET_COMMENT");
    },
  },
  getters: {
    comment: (state) => state.comment,
    commentVisible: (state) => state.commentVisible,
  },
};

export default comment;
