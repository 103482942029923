import a from '@/utils/axios/axios'
import jwt from 'jsonwebtoken'

const user = {
  namespaced: true,
  state: () => ({
    user: null,
    login_loading: false,
    currentTeamId: null,
    refreshTokenInterval: null
  }),
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setCurrentTeamId(state, id) {
      if(!id) return
      state.currentTeamId = id
    },
    setToken(state, payload) {
      state.token = payload
    },
    setJoinCode(state, { team_id, join_code }) {
      let new_user = { ...state.user }
      let index = new_user.teams.findIndex(team => team.id == team_id)
      new_user.teams[index]['join_code'] = join_code
      state.user = new_user
    },
    setRefreshTokenInterval(state, interval) {
      state.refreshTokenInterval = interval
    }
  },
  actions: {
    initUser({ commit, dispatch }, token) {
      let user = jwt.decode(token)

      if(!user?.currentTeamId) {
        user['currentTeamId'] = user['teams'][0]?.currentTeamId
      }

      commit('setUser', user)
      window.localStorage.setItem('tiimio-token', token)
      window.localStorage.setItem('tiimio-lang', user.language || 'en')
      window.localStorage.setItem('tiimio-latest-team', user.currentTeamId)

      dispatch('getRefreshTokenLater')
      // TODO: refetch token after a time
      // dispatch('initACurrentTeam')
    },
    getRefreshTokenLater({ commit, getters, dispatch }) {
      clearTimeout(getters.refreshTokenInterval)

      const interval = setTimeout(() => {
        // get refresh token
        a.get(`/auth/refresh?currentTeamId=${getters.currentTeamId}`)
          .then(e => {
            dispatch('initUser', e.data.token)
          })

      }, 1000 * 60 * 10) // 10 minutes

      commit('setRefreshTokenInterval', interval)
    },
    initACurrentTeam({ getters, commit }) {
      let team_id = window.localStorage.getItem('tiimio-latest-team')
      let team = getters.userTeams.find(team => team.id == team_id)

      if(team) {
        // TODO pitää tsekata onko joukkue tosiaan olemassa
        team_id = parseInt(team_id)
        commit('setCurrentTeamId', team_id)
      } else if(getters.userTeams.length) {
        const { id } = getters.userTeams[0]
        commit('setCurrentTeamId', id)
        window.localStorage.setItem('tiimio-latest-team', id)
      }

      return
    },
    changeCurrentTeamId({ commit }, id) {
      commit('setCurrentTeamId', id)
      window.localStorage.setItem('tiimio-latest-team', id)
    },
    openCustomerPortal({ getters }) {
      return new Promise((resolve, reject) => {
        a.post('/stripe/create-customer-portal-session', { team_id: getters.currentTeamId})
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    orderProduct({ getters }, { price_id }) {
      return new Promise((resolve, reject) => {
        a.post('/stripe/customer-session-url', { lookup_key: price_id, team_id: getters.currentTeamId})
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    joinTeam({ dispatch }, { invite_code, join_code }) {
      return new Promise((resolve, reject) => {
        a.post('/team/join', { invite_code, join_code })
          .then(res => {
            resolve(res.data)
            dispatch('changeCurrentTeamId', res.data.team.id)
          })
          .catch(err => reject(err))
      })
    },
    logout({ commit }) {
      window.localStorage.removeItem('tiimio-token')
      commit('setUser', null)
    }
  },
  getters: {
    userTeams: state => state.user ? state.user.teams : [],
    currentTeam: (state, getters) => {
      if(!state.user?.currentTeamId) return getters.userTeams[0] ? getters.userTeams[0] : null
      return getters.userTeams.find(team => team.id == Number(state.user?.currentTeamId))
    },
    currentTeamId: state => state.user?.currentTeamId,
    isAppleTesters: (_, getters) => getters.currentTeamId == 119,
    user: (state, getters) => {
      return {
        ...state.user,
        first_name: state.user?.full_name?.split(' ')[0],
        last_name: state.user?.full_name?.split(' ')[state.user.full_name.split(' ').length - 1],
        isAdmin: getters.isAdmin,
        isOrderer: getters.isOrderer,
      }
    },
    loggedIn: state => !!state.user,
    isOrderer: (state, getters) => {
      if(!getters.currentTeam) return false
      return getters.currentTeam.team_orderer
    },
    isAdmin: (state, getters) => {
      if(!getters.currentTeam) return false
      return getters.currentTeam.team_admin
    },
    currentPlanId: (_, getters) => {
      return getters.currentTeam?.plan_id
    },
    currentSportId: (_, getters) => getters.currentTeam?.sport_id,
    refreshTokenInterval: state => state.refreshTokenInterval,
    inLeague: (_, getters) => !!getters.currentTeam?.league_id,
    leagueId: (_, getters) => getters.currentTeam?.league_id,
    timesDisabled: (_, getters) => getters.currentTeam?.disable_times || !getters.currentTeam?.sport_times_available,
    current_sport_id: (_, getters) => {
      return getters.currentTeam?.sport_id
    },
    userFirstNamePretty: (_, getters) => {
      let name = getters.user?.full_name.split(' ')[0]
      return name?.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }
  }
}

export default user