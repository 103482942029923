<template>
  <v-app style="height: 100%;" id="io-app">
    <!-- <div style="background-color: white; position: absolute; z-index: 99999; right: 0;">
      mobile breakpin {{ $vuetify.breakpoint.mobile }}
      <br>
      route setit {{ !$route.meta.hideSideBar && !$route.meta.hideNavBar }}
      <br>
      showMobileNavbar {{ showMobileNavBar }}
      <br>
    </div> -->
    <!-- need user currentTeam -->
    <product-fruits
      v-if="user"
      project-code="7LanuZNpsnyEaSZL"
      :language="user.language"
      :username="user.email"
      :email="user.email"
      :firstname="user.first_name"
      :lastname="user.last_name"
      :sign-up-at="user.joined"
      :role="user.isOrderer ? 'orderer' : 'user'"
      :props="{ team_admin: user.isAdmin, sport: current_sport_id, team_orderer: user.isOrderer, plan_id: currentPlanId, mobile_user: $vuetify.breakpoint.mobile, app_user: isApp }"
    />
    <SideBar :items="items" v-if="!$route.meta.hideSideBar"></SideBar>
    <NavBar v-if="!$route.meta.hideNavBar && !$vuetify.breakpoint.mobile"></NavBar>
    <Notification></Notification>
    <v-main style="height: 100vh;">
      <router-view/>
    </v-main>
    <MobileBar
      v-if="$vuetify.breakpoint.mobile && (!$route.meta.hideSideBar && !$route.meta.hideNavBar) && showMobileNavBar"
      :items="items"
    ></MobileBar>
    <v-btn @click="toggleSideBar()" v-if="$vuetify.breakpoint.mobile && (!$route.meta.hideSideBar && !$route.meta.hideNavBar) && showMobileNavBar" color="primary" fab style="position: fixed; left: 5px; bottom: 15px; z-index: 1000">
      <v-icon v-if="show_sidebar">mdi-close</v-icon>
      <v-icon v-else>mdi-menu</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import NavBar from '@/components/navigation/NavBar.vue'
import SideBar from '@/components/navigation/SideBar.vue'
import MobileBar from '@/components/navigation/MobileBar.vue'
import Notification from '@/components/notification/Notification.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import 'floating-vue/dist/style.css'
// import Vue from 'vue'

export default {
  name: 'App',
  components: { NavBar, SideBar, Notification, MobileBar },
  created() {
    this.$router.afterEach(() => {
      window.productFruits?.pageChanged?.();
    });

    const token = window.localStorage.getItem('tiimio-token')

    this.setTheme()

    if(token) {
      this.initUser(token)
    }

    // Vue.nextTick(() => {
    //   this.track('App opened', {
    //     distinct_id: this.user?.email,
    //     "Mobile app": this.isApp
    //   })
    // })
  },
  mounted() {
    this.api_mount_interval = setInterval(() => {
      if(window.productFruits?.api?.checklists) {
        window.productFruits.api.checklists.listen('item-launched', (_id, internalId) => {
          if(internalId == 'video-added') {
            this.$router.push({path: '/videos'})
            this.$router.push({path: '/videos', query: { view: 'add-video' }})
          } else if(internalId == 'event-tagged') {
            this.$router.push({path: '/videos', query: { action: 'open-some' }})
          } else if(internalId == 'add-member') {
            this.$router.push({path: '/admin/team', query: { view: 'invite' }})
          } else if(internalId == 'draw') {
            this.$router.push({path: '/videos', query: { action: 'open-some' }})
          }
        }); 
        this.fruit_id++
        clearInterval(this.api_mount_interval)
      } 
    }, 200)
  },
  data: () => ({
    api_mount_interval: null,
    fruit_id: 0
  }),
  methods: {
    ...mapActions('user', [
      'initUser'
    ]),
    ...mapActions('noti', [
      'success',
      'error'
    ]),
    ...mapMutations([
      'toggleSideBar'
    ]),
    setTheme() {
      if(window.localStorage.getItem('system-pref-dark') === 'true') {
      const hasDarkPreference = window.matchMedia("(prefers-color-scheme: dark)").matches;
        if(hasDarkPreference) {
          window.localStorage.setItem('tiimio-dark', true)
          this.$vuetify.theme.dark = window.localStorage.getItem('tiimio-dark') == 'true'
          return
        }
        window.localStorage.setItem('tiimio-dark', false)
        return
      }
      const dark = window.localStorage.getItem('tiimio-dark')
      if(dark) {
        this.$vuetify.theme.dark = dark == 'true'
        return 
      }
      window.localStorage.setItem('tiimio-dark', false)
    }
  },
  computed: {
    ...mapGetters([
      'uiState',
      'show_sidebar',
      'showMobileNavBar'
    ]),
    ...mapGetters('user', [
      'currentTeam',
      'user',
      'current_sport_id',
      'currentPlanId'
    ]),
    items() {
      return [
        {text: this.$t('sidebar.games'), icon: 'mdi-play-box-multiple', path: '/games', no_mobile: false },
        {text: this.$t('sidebar.videos'), icon: 'mdi-video', path: '/videos', no_mobile: false },
        {text: this.$t('sidebar.library'), icon: 'mdi-folder', path: '/library', no_mobile: false },
        {text: this.$t('sidebar.times'), icon: 'mdi-timer', path: '/times', no_mobile: true },
        {text: this.$t('sidebar.filter'), icon: 'mdi-filter', path: '/filters', no_mobile: false },
        // {text: this.$t('sidebar.maps'), icon: 'mdi-dots-hexagon', path: '/maps', no_mobile: true },
        {text: this.$t('sidebar.tags'), icon: 'mdi-tag', path: '/tags', sideonly: true, no_mobile: true },
        {text: this.$t('sidebar.data'), icon: 'mdi-counter', path: '/data', sideonly: true, no_mobile: true },
        {text: this.$t('sidebar.team'), icon: 'mdi-account-group', path: '/admin/team', sideonly: true, no_mobile: true },
        {text: this.$t('sidebar.settings'), icon: 'mdi-cog', path: '/settings', sideonly: true, mobile_only: true, no_mobile: true },
      ]
    },
    isApp() {
      if(window.localStorage.getItem('tiimi-application')) return window.localStorage.getItem('tiimi-application')

      let standalone = window.navigator.standalone
      let userAgent = window.navigator.userAgent.toLowerCase()
      let safari = /safari/.test(userAgent)
      let ios = /iphone|ipod|ipad/.test(userAgent)

      if (ios) {
        if (!standalone && safari) {
          // Safari
          return false
        } else if (!standalone && !safari) {
          // iOS webview
          return true
        }
      } else {
        if (userAgent.includes('wv')) {
          return true
          // Android webview
        } else {
          return false
          // Chrome
        }
      }

      return false
    }
  }
};
</script>

<style lang="scss">
#io-app {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}
// * {
//   padding: 0px;
//   margin: 0px;
// }
html {
  scrollbar-width: none;
}
html::-webkit-scrollbar {
  display: none;
}

.container {
  overflow-y: scroll;
}

.io-main {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.digital-font {
  font-family: 'Orbitron', sans-serif;
}

// prevents "bounciness" when scrolling over
:root {
  overscroll-behavior: none;
}

.productfruits--checklist-panel {
    z-index: 0;
}

.productfruits--checklist-panel-embedded {
  z-index: 0 !important;
}

.hideScroll {
  scrollbar-width: none;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

#io-player > div > div {
  scrollbar-width: none;
}

#io-player > div > div::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  /* this will hide the scrollbar in mozilla based browsers */
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  /* this will hide the scrollbar in internet explorers */
  -ms-overflow-style: none;
}

.tooltip {
  // ...

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: pink;
      color: white;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
    }

    .popover-arrow {
      border-color: pink;
    }
  }
}
</style>


