require('dotenv').config()
const axios = require('axios')

var token = window.localStorage.getItem('tiimio-token')


const local = window.location.href.includes('localhost')
const dev = window.location.href.includes('dev.')

// Do not try this at home please
let apiBase;
if(local) {
  apiBase = 'http://localhost:4040'
} else if(dev) {
  apiBase = 'https://tiimi-development.herokuapp.com'
} else {
  apiBase = 'https://api.tiimi.io'
}

const config = {
  baseURL: apiBase,
  headers: {'Authorization': `Bearer ${token}`}
}

var ax = axios.create(config)

console.log('Api base:')
console.log(apiBase)

ax.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('tiimio-token');

    if (token) {
      config.headers['Authorization'] = `Bearer ${ token }`;
    }

    return config;
  }, 

  (error) => {
    return Promise.reject(error);
  }
);

export default ax