// import a from '@/utils/axios/axios'
// import router from '@/router/index.js'
// import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue'

const timeline = {
  namespaced: true,
  state: () => ({
    zoom: 1, // 1 = 100% - aka whole time on timeline - 10: 1000% meaning an 100 minute video and 10 minutes showing on timeline
    pixels_hidden_left: 0, // the time thats show on the lefternmost edge of .io-timeline-cont-row-main
    pixels_hidden_top: 0,
    show_meta_tags: false,
    show_tags_type: 'action'
  }),
  mutations: {
    ZOOM_IN(state, val = 0.3) {
      if(state.zoom < 10) state.zoom = parseFloat((state.zoom + val).toFixed(1))
    },
    ZOOM_OUT(state, val = 0.3) {
      if(state.zoom > 1) state.zoom = parseFloat((state.zoom - val).toFixed(1))
    },
    SET_PIXELS_HIDDEN_LEFT(state, val) {
      state.pixels_hidden_left = val
    },
    SET_PIXELS_HIDDEN_TOP(state, val) {
      state.pixels_hidden_top = val
    },
    SET_SHOW_TAGS_TYPE(state, val) {
      if(val != 'action' && val != 'metadata') return
      state.show_tags_type = val
    }
  },
  actions: {
    zoomIn({ commit, getters, dispatch }, { cursor_left, main_left, value }) {
      const zoom_in_value = value || 0.2
      const offset_left = getters.calculate_zoom_offset({ 
        prev_zoom: getters.zoom, 
        prev_width: getters.whole_bar_width, 
        zoom_in_value, 
        cursor_left, 
        main_left 
      })

      commit('ZOOM_IN', zoom_in_value)
      
      Vue.nextTick(() => {
        dispatch('moveHorisontally', -offset_left)
      })
    },
    zoomOut({ commit, dispatch, getters }, { cursor_left, main_left, value }) {
      const zoom_in_value = value || 0.2
      const offset_left = getters.calculate_zoom_offset({ 
        prev_zoom: getters.zoom, 
        prev_width: getters.whole_bar_width, 
        zoom_in_value, 
        cursor_left, 
        main_left 
      })
      
      commit('ZOOM_OUT', zoom_in_value)
      
      Vue.nextTick(() => {
        dispatch('moveHorisontally', offset_left)
      })
    },
    moveScrollbar({ dispatch, getters }, x) {
      const amount = getters.whole_bar_width / getters.main_width * x
      console.log(amount)
      if(!amount) return
      dispatch('moveHorisontally', -amount)
    },
    moveCursor({ dispatch, getters, rootGetters }, x) {
      if(!x) return

      const time = x / getters.whole_bar_width * rootGetters['player/duration'] + rootGetters['player/accurateCurrentTime']
      dispatch('player/seek', time, { root: true })
    },
    moveHorisontally({ commit, getters }, x) {
      const { pixels_hidden_left, main_width, whole_bar_width } = getters
      const max_left_minus = whole_bar_width - main_width
      const new_try = pixels_hidden_left - x

      if(new_try <= 0) commit('SET_PIXELS_HIDDEN_LEFT', 0)
      else if(new_try > max_left_minus) commit('SET_PIXELS_HIDDEN_LEFT', max_left_minus)
      else commit('SET_PIXELS_HIDDEN_LEFT', new_try)
    }
  },
  getters: {
    zoom: state => state.zoom,
    pixels_hidden_left: state => state.pixels_hidden_left,
    pixels_hidden_top: state => state.pixels_hidden_top,
    show_tags_type: state => state.show_tags_type,
    tag_clips: (state, getters, rootState, rootGetters) => id => {
      // const all_tags = rootGetters['tag/all_tags_with_groupdata']

      // const tag = all_tags.find(t => t.id == id)
      const video_clips = rootGetters['videos/videoClips']
      const ongoing_clips = rootGetters['ticks/ongoing_clips']
      const all_clips = video_clips.concat(ongoing_clips)

      let clips;

      if(getters.show_tags_type == 'action') {
        clips = all_clips.filter(clip => {
          // action
          let main_tag;
          if(clip.tags) main_tag = clip.tags.find(t => !!t.main_tag)
          else main_tag = clip.main_tag
          return main_tag?.id == id
  
          // metadata
        })
      } else {
        clips = all_clips.filter(clip => {
          // action
          let main_tag;
          if(clip.tags) main_tag = clip.tags.find(t => t.id == id)
          else main_tag = clip.meta_tags.find(t => t.id == id)
          return !!main_tag
  
          // metadata
        })
      }
      // const ongoing_clips = rootGetters['tick/ongoing_clips']

      return clips
      // Jos tagi on action, kaikki klipit missä se on main_tag
      // Jos tagi on metadata, pitäs ottaa kaikki klipit missä se on ylipäänsä
    },
    main_width: (_, __, ___, rootGetters) => {
      rootGetters['draw/canvas_dimensions_key']
      const rect = document.querySelector('.io-timeline-cont-row-main')?.getBoundingClientRect()
      return rect?.width || 0
    },
    cont_height: (_, __, ___, rootGetters) => {
      rootGetters['draw/canvas_dimensions_key']
      const rect = document.querySelector('.io-timeline-cont')?.getBoundingClientRect()
      return rect?.height || 0
    },
    whole_bar_width: (_, getters) => {
      return getters.zoom * getters.main_width
    },
    clip_bar_left: (_, getters, __, rooGetters) => (clip) => {
      const duration = rooGetters['player/duration']
      const starttime = clip.starttime
      const { whole_bar_width } = getters 

      const left = starttime / duration * whole_bar_width
      return left
    },
    clip_bar_width: (_, getters, __, rooGetters) => (clip) => {
      const duration = rooGetters['player/duration']
      const current = rooGetters['player/currentTime']
      let { starttime, endtime } = clip
      const { whole_bar_width } = getters
      if(!endtime) if(clip.will_end) endtime = clip.will_end
      else endtime = current

      const width = (endtime - starttime) / duration * whole_bar_width
      return Math.max(width, 5)
    },
    scrollbar_width: (_, getters) => {
      return getters.main_width * (getters.main_width / getters.whole_bar_width)
    },
    scrollbar_left: (_, getters) => {
      return getters.main_width * (getters.pixels_hidden_left / getters.whole_bar_width)
    },
    calculate_zoom_offset: (_, getters) => ({ cursor_left, main_left, zoom_in_value, prev_zoom, prev_width }) => {
      if(!cursor_left || !main_left) return getters.main_width * 0.5
      const cursor_main_left = cursor_left - main_left
      // zoom is not changed at this point
      const growth = getters.main_width * (prev_zoom + zoom_in_value) - prev_width
      const percentage_of_cursor_on_bar = cursor_main_left / getters.main_width
      const offset_left = growth * percentage_of_cursor_on_bar

      return offset_left
    },
    calculate_cursor_left: (_, getters, ___, rootGetters) => {
      const current = rootGetters['player/accurateCurrentTime']
      const duration = rootGetters['player/duration']

      const time_left = getters.pixels_hidden_left / getters.whole_bar_width * duration
      const time_right = (getters.pixels_hidden_left + getters.main_width) / getters.whole_bar_width * duration

      if(current < time_left) return 0
      if(current > time_right) return getters.main_width

      return (( current - time_left ) / ( time_right - time_left )) * getters.main_width
    },
    clip_name: () => (clip) => {
      let base;
      if(clip.tags) base = clip.tags
      else base = [clip.main_tag]
      return base.map(t => t.name).join(' ')
    }
  }
}

export default timeline