/* eslint-disable no-unused-vars */
import a from '@/utils/axios/axios'
import Vue from 'vue'
import { 
  handleChosenTagsCurrentClip,
  handleChosenTagsNextClip,
  handleChosenTagsHotkey
} from '../../utils/clipper/handleRules.js'

const team = {
  namespaced: true,
  state: () => ({
    template: null, // template or "manual"
    tags: [],
    starttime: null,
    map_color: '#cccccc',
    default_color: '#cccccc',
    points: [],
    saving: false,
    editing_tags: false,
    editing_form_items: false,
    answers: [],
    loading: false
  }),
  mutations: {
    SET_PRE_ANSWERS(state, { answers }) {
      let mod = [...state.answers]

      if(!answers || !answers.length) return

      answers.forEach(a => {
        mod = mod.filter(a2 => a2.button_template_prequisite_question_id != a.button_template_prequisite_question_id)
      })

      mod = mod.concat(answers)

      state.answers = mod
    },
    REMOVE_ANSWERS_BY_QUESTION_ID(state, id) {
      state.answers = [...state.answers].filter(a => a.button_template_prequisite_question_id != id)
    },
    SET_EDITING_FORM_ITEMS(state, val) {
      state.editing_form_items = val
    },
    CLEAR(state) {
      state.points = []
      state.starttime = null
      state.map_color = state.default_color
    },
    SET_EDITING_TAGS(state, val) {
      state.editing_tags = val
    },
    SET_TEMPLATE(state, template) {
      state.template = template
    },
    ADD_TAG(state, tag) {
      state.tags = [...state.tags].concat(tag)
    },
    REMOVE_TAG(state, tag) {
      state.tags = [...state.tags].filter(t => t.id != tag.id)
    },
    REMOVE_TAG_BY_ID(state, id) {
      state.tags = [...state.tags].filter(t => t.id != id)
    },
    SET_MAP_COLOR(state, color) {
      state.map_color = color
    },
    SET_TAGS(state, tags) {
      state.tags = tags
    },
    SET_MAP_POINTS(state, payload) {
      let {map_base_id, points} = payload
      points = points.map(p => {
        return {...p, map_base_id}
      })

      state.points = [...state.points].filter(p => p.map_base_id != map_base_id)
      state.points = [...state.points].concat(points)
    },
    REMOVE_TAGS_BY_GROUPID(state, id) {
      state.tags = [...state.tags].filter(t => t.group_id != id)
    },
    ERASE_MAP_POINTS(state) {
      state.points = [] 
    },
    SET_EVENT_STARTTIME(state, time) {
      state.starttime = time
    },
    SET_SAVING(state, s) {
      state.saving = s
    }
  },
  actions: {
    handleTagClicked({ dispatch, commit, getters, rootGetters }, tag) {
      const wasChosen = !!getters.tags.find(t => t.id == tag.id)
      const group = rootGetters['tag/tag_groups'].find(g => g.id == tag.group_id)

      // add to tags if not chosen
      if(!wasChosen) {
        if(group.one_tag_only) {
          commit('REMOVE_TAGS_BY_GROUPID', group.id)
        }
        commit('ADD_TAG', tag)
        commit('SET_MAP_COLOR', tag.map_color || getters.map_color )
      // remove from tags if not chosen
      } else {
        commit('REMOVE_TAG', tag)
      }

      if(getters.template.save_on_tag_click) {
        dispatch('saveEvent')
        return
      }

      const new_tags = handleChosenTagsCurrentClip(
        getters.tags,
        getters.rules,
        rootGetters['tag/tag_groups'], 
        tag
      )

      if(new_tags.length == 0) commit('SET_MAP_COLOR', getters.default_color )

      commit('SET_TAGS', new_tags)

      // TODO: add on tag
    },
    removeSelect({ commit }) {
      commit('SET_TEMPLATE', null)
    },
    handleSelectChange({ commit, getters, dispatch, rootGetters }, {tags, group_id}) {
      const chosen_before = [...getters.tags]
      
      // remove all group tags
      commit('REMOVE_TAGS_BY_GROUPID', group_id)

      if(!tags) return

      if(typeof tags == 'object') {
        // an array of new tags
        tags.forEach(tag_id => {
          const wasChosen = !!chosen_before.find(t => t.id == tag_id)
          if(wasChosen) {
            // add to tags without handling tag add
            commit('ADD_TAG', rootGetters['tag/tag_by_id'](tag_id))
          } else {
            // add with handling rules and such
            dispatch('handleTagClicked', rootGetters['tag/tag_by_id'](tag_id))
          }
        })
      } else {
        const wasChosen = !!chosen_before.find(t => t.id == tags)

        if(!wasChosen) dispatch('handleTagClicked', rootGetters['tag/tag_by_id'](tags))
      }
    },
    setMapPoints({ commit, dispatch, getters }, e) {
      commit('SET_MAP_POINTS', e)

      if(getters.template.save_on_map_click) {
        dispatch('saveEvent', { video_id: e.video_id })
      }
    },
    setEventTime({ commit, rootGetters, getters }) {
      if(getters.starttime) {
        commit('SET_EVENT_STARTTIME', null)
        return
      }

      const time = rootGetters['player/accurateCurrentTime']
      commit('SET_EVENT_STARTTIME', time)
    },
    saveEvent({ dispatch, getters, rootGetters, commit }, { video_id }) {
      const body = {
        title: getters.clip_title || 'No title',
        starttime: getters.starttime || rootGetters['player/accurateCurrentTime'],
        endtime: null,
        video_id,
        tags: getters.tags.map(t => t.id).concat(getters.pre_question_tags),
        map_color: getters.map_color,
        points: getters.points.map(p => { return {...p, map_base: { id: p.map_base_id }}}),
        leaguewide: false,
        team_id: rootGetters['user/currentTeamId'],
        game_id: null,
        is_point: true
      }

      commit('SET_SAVING', true)

      let ne = handleChosenTagsNextClip(        
        getters.tags,
        getters.rules,
        rootGetters['tag/tag_groups']
      )

      return dispatch('clip/addClip', body, { root: true })
        .then(() => {
          dispatch('noti/success', 'Clip saved', {root:true})
          commit('CLEAR')
          commit('SET_TAGS', ne)
        })
        .catch(e => {
          dispatch('noti/error', e, {root:true})
        })
        .finally(() => {
          commit('SET_SAVING', false)
        })
    },
    handleKeyDown({ dispatch, getters, commit, rootGetters }, e) {
      if(!rootGetters['keybinds']) return

      const tag_groups = rootGetters['tag/tag_groups']

      rootGetters['tag/all_tags'].forEach(t => {
        if(t.hotkey == e.key) dispatch('handleTagClicked', t)
      })

      const tags = handleChosenTagsHotkey(getters.tags, getters.rules, tag_groups, undefined, e.key)
      commit('SET_TAGS', tags)
    },
    setGroupPreAnswers({ commit }, { tag_group_id, answers, button_template_prequisite_question_id, video_id }) {
      if(!answers || !answers.length) commit('REMOVE_ANSWERS_BY_QUESTION_ID', button_template_prequisite_question_id)

      if(typeof answers == 'object') {
        answers = answers.map(e => {
          return {
            tag_group_id, 
            tag_id: e, 
            button_template_prequisite_question_id, 
            video_id
          }
        })
      } else {
        answers = [{
          tag_group_id, 
          tag_id: answers, 
          button_template_prequisite_question_id, 
          video_id
        }]
      }

      commit('SET_PRE_ANSWERS', { answers })
    },
    addAnswers({ commit, rootGetters }, { video_id, answers }) {
      const team_id = rootGetters['user/currentTeamId']

      return new Promise((resolve, reject) => {
        a.post(`/template/answer/team/${team_id}/video/${video_id}`, { answers })
          .then(res => {
            if(answers.length) commit('SET_EDITING_FORM_ITEMS', false)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    chooseTemplateById({ commit, rootGetters }, id) {
      if(id == 'manual') {
        commit('SET_TEMPLATE', { id: 'manual' })
      }

      const template = rootGetters['template/templates'].find(t => t.id == id)

      if(!template) return

      window.localStorage.setItem('tiimi-chosen-template-id', id)

      if(template?.form_items) {
        commit('SET_PRE_ANSWERS', {answers: template.answers})
      }

      if(template?.form_items && !template.answers?.length) {
        commit('SET_EDITING_FORM_ITEMS', true)
      } else {
        commit('SET_EDITING_FORM_ITEMS', false)
      }
      
      commit('SET_TEMPLATE', template)
    }
  },
  getters: {
    tags: state => state.tags,
    template: state => state.template,
    rules: (state) => state.template?.rules,
    starttime: (state) => state.starttime,
    map_color: (state) => state.map_color,
    saving: (state) => state.saving,
    default_color: (state) => state.default_color,
    add_on_tag: (state) => state.add_on_tag || state.add_on_tag_click,
    points: state => state.points,
    map_points: state => map_base_id => {
      return state.points.filter(p => p.map_base_id == map_base_id)
    },
    clip_title: (getters) => {
      let sorted = getters.tags.sort((a, b) => a.group_id - b.group_id)
      let name = ''
      sorted.forEach(t => {
        if(!name) name += t.tag_name
        else name += ` | ${t.tag_name}`
      })
      return name
    },
    editing_tags: state => state.editing_tags,
    group_tags_by_id: state => id => {
      return state.tags.filter(t => t.group_id == id)
    },
    editing_form_items: state => state.editing_form_items,
    answers: state => state.answers,
    answers_by_question_id: (_, getters) => id => {
      return getters.answers.filter(a => a.button_template_prequisite_question_id == id)
    },
    answers_ids_by_question_id: (_, getters) => id => {
      return getters.answers.filter(a => a.button_template_prequisite_question_id == id).map(i => i.tag_id)
    },
    answers_tags_by_group_id: (_state, getters, _rootState, rootGetters) => group_id => {
      let answer_tags = []
      const questions = getters.template?.form_items?.filter(f => f.tag_group_id == group_id)

      questions?.forEach(q => {
        answer_tags = answer_tags.concat(getters.answers_by_question_id(q.id))
      })

      if(answer_tags.length) return answer_tags.map( a => rootGetters['tag/tag_by_id'](a.tag_id) )
      else return rootGetters['tag/group_by_id'](group_id)?.tags
    },
    pre_question_tags: (_, getters) => {
      let tags = []
      getters.template?.form_items.filter(i => i.add_to_event).forEach(fi => {
        tags = tags.concat( getters.answers_ids_by_question_id(fi.id) )
      })

      return tags;
    }
  }
}

export default team