import a from '@/utils/axios/axios'
// import { v4 as uuidv4 } from 'uuid';
// import Vue from 'vue';

const exp = {
  namespaced: true,
  state: () => ({
    /*
      clip DOING
      multiple clips TODO
      image TODO
      filter TODO
      folder TODO
    */
    export_type: null,
    clip_id: null,
    loading_clip: false,
    exporting_clip: null,
    export_ongoing: false,
    past_export: null,
    export_items: [],
    loading_exports: false
  }),
  mutations: {
    SET_LOADING_CLIP(state, val) {
      state.loading_clip = val
    },
    SET_EXPORTING_CLIP(state, val) {
      state.exporting_clip = val
    },
    SET_PAST_EXPORT(state, val) {
      state.past_export = val
    },
    SET_EXPORT_ITEMS(state, val) {
      state.export_items = val
    },
    SET_LOADING_EXPORTS(state, val) {
      state.loading_exports = val
    },
    SET_EXPORT_ONGOING(state, val) {
      state.export_ongoing = val
    }
  },
  actions: {
    async init({commit, dispatch}) {
      commit('SET_LOADING_EXPORTS', true)
      await dispatch('fetchData')
      commit('SET_LOADING_EXPORTS', false)
    },
    async singleInit({dispatch, commit}) {
      commit('SET_LOADING_EXPORTS', true)
      await dispatch('fetchPastExport')
      commit('SET_LOADING_EXPORTS', false)
    },
    async fetchData({commit, dispatch, rootGetters}) {
      const userExports = await dispatch('exportByTeamId', rootGetters['user/currentTeamId'])
      let items = [];
      for(let item of userExports) {
        try {
          // const clip = await dispatch('clip/clipById', item.clip_id, {root: true})
          const created = await dispatch('parseCreated', item.export_started) 
          let exportItem = {
              title: item.clip_title,
              clip_id: item.clip_id,
              created: created,
              download_url: item.download_url,
              no_graphics_clip_location: item.no_graphics_clip_location,
              export_id: item.export_id,
              has_graphics: item.has_graphics,
              clipped: (item.export_state == 'clipped' || item.export_state == 'export_finished'),
              graphics: (item.export_state == 'export_finished'),
          }
          items.push(exportItem)
        } catch (error) {
          // console.error('Virhe haettaessa klippiä, klippi on luultavasti poistettu, mutta export objekti on olemassa: ', error);
        }
      }
      commit('SET_EXPORT_ITEMS', items)
    },
    async fetchPastExport({getters, dispatch, commit}) {
      let items = getters['past_export']
      let itemsToDisplay = []
      if(!getters['past_export']) items = await dispatch('exportByClipId', getters['exporting_clip'].id)
      if(!items) return
      try {
      // const clip = this.export_ongoing ? await dispatch('clip/clipById', item.id, {root: true}) : await dispatch('clip/clipById', item.clip_id, {root: true})
      for(let item of items) {
        const created = await dispatch('parseCreated', item.export_started) 
        const dataItem = {
            title: item.clip_title,
            clip_id: item.clip_id,
            created: created,
            download_url: item.download_url,
            no_graphics_clip_location: item.no_graphics_clip_location,
            export_id: item.export_id,
            has_graphics: item.has_graphics,
            clipped: (item.export_state == 'clipped' || item.export_state == 'export_finished'),
            graphics: (item.export_state == 'export_finished'),
        }
        itemsToDisplay.push(dataItem)
      }
      commit('SET_EXPORT_ITEMS', itemsToDisplay)
    } catch (error) {
      // console.error('Virhe haettaessa tietoja: ', error);
    }
    },
    parseCreated(_, original) {
      const date = new Date(original);

      if (isNaN(date.getTime())) {
        return 'Date not found';
      }

      const dd = date.getDate().toString().padStart(2, '0');
      const mm = (date.getMonth() + 1).toString().padStart(2, '0');
      const yyyy = date.getFullYear();

      return `${dd}.${mm}.${yyyy}`;
    },
    async startClipExport({ dispatch, commit, getters }, clip_id) {
      commit('SET_LOADING_CLIP', true)
      const export_promise = await dispatch('exportByClipId', clip_id)
      const clip_promise = await dispatch('clip/clipById', clip_id, { root: true })
      
      await Promise.all([ export_promise, clip_promise ])
      .then(res => {
        commit('SET_EXPORTING_CLIP', res[1].data)
        commit('SET_PAST_EXPORT', res[0])
        if(getters['past_export']) dispatch('singleInit')
      })
      .catch(e => {
        throw new Error(e)
      })
      .finally(() => {
        commit('SET_LOADING_CLIP', false)
      })
    },
    displayExportState({dispatch, commit}, val) {
      commit('SET_EXPORT_ONGOING', val)
      dispatch('singleInit')
    },
    exportByTeamId(_, team_id) {
      return new Promise((resolve, reject) => {
        a.get(`/export/export-state/team/${team_id}`)
          .then(e => resolve(e.data))
          .catch(e => reject(e))
      })
    },
    exportByClipId(_, clip_id) {
      return new Promise((resolve, reject) => {
        a.get(`/export/export-state/clip/${clip_id}`)
          .then(e => {
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    exportByExportId(_, export_id) {
      return new Promise((resolve, reject) => {
        a.get(`/export/export-state/${export_id}`)
        .then(e => {
          resolve(e.data)
        })
        .catch(e => {
          reject(e)
        })
      })
    },
    downloadItem(_, item) {
        let url;

        // if: klipattu ja grafiikoita käsitellään
        if(item.clipped && !item.graphics) url = item.no_graphics_clip_location
        else url = item.has_graphics ? item.download_url : item.no_graphics_clip_location

        // Luodaan linkki, joka osoittaa videon URL:iin ja lisätään download-attribuutti
        const a = document.createElement('a');
        a.href = url;
        a.download = `${item.title}.mp4`;
        document.body.appendChild(a);
                    
        // Simuloidaan linkin klikkaus
        a.click();
                    
        // Poistetaan linkki
        document.body.removeChild(a);
    },
    clearAll({commit}) {
      commit('SET_EXPORTING_CLIP', null)
      commit('SET_PAST_EXPORT', null)
      commit('SET_EXPORT_ITEMS', [])
      commit('SET_LOADING_CLIP', false)
      commit('SET_EXPORT_ONGOING', false)
    }
  },
  getters: {
    loading_clip: state => state.loading_clip,
    exporting_clip: state => state.exporting_clip,
    export_ongoing: state => state.export_ongoing,
    past_export: state => state.past_export,
    loading_exports: state => state.loading_exports,
    export_items: state => state.export_items
  }
}

export default exp