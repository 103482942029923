import a from '@/utils/axios/axios'

const team = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    saveFile({ rootGetters }, { text, title }) {
      const team_id = rootGetters['user/currentTeamId']
      return new Promise((resolve, reject) => {
        a.post(`/textfile`, { text, title, team_id })
          .then(res => {
            resolve(res.data)
          })
          .catch(e => reject(e))
      })
    },
    getFile(_, { id }) {
      return new Promise((resolve, reject) => {
        a.get(`/textfile/${id}`)
          .then(res => {
            resolve(res.data)
          })
          .catch(e => reject(e))
      })
    },
    updateFile(_, { id, text, title }) {
      return new Promise((resolve, reject) => {
        a.put(`/textfile/${id}`, { text, title })
          .then(res => {
            resolve(res.data)
          })
          .catch(e => reject(e))
      })
    }
   },
  getters: {
    users: state => state.users,
  }
}

export default team