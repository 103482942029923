import a from "@/utils/axios/axios";
import axios from "axios";

const fileUpload = {
  namespaced: true,
  state: {
    uploadProgress: {},
    uploadError: null,
    filesUploaded: [],
  },
  mutations: {
    SET_UPLOAD_PROGRESS(state, { fileName, progress }) {
      state.uploadProgress = {
        ...state.uploadProgress,
        [fileName]: progress,
      };
    },
    SET_UPLOAD_ERROR(state, error) {
      state.uploadError = error;
    },
    ADD_UPLOADED_FILE(state, fileName) {
      state.filesUploaded.push(fileName);
    },
    CLEAR_UPLOADS(state) {
      state.uploadProgress = {};
      state.uploadError = null;
      state.filesUploaded = [];
    },
  },
  actions: {
    async getPresignedUrl({ commit }, { name, type, folder }) {
      return new Promise((resolve, reject) => {
        // Fetch presigned URL from backend API
        a.post('/file/get-presigned-url', {
          fileName: name,
          fileType: type,
          folder: folder
        })
        .then(response => {
          resolve({ url: response.data.url, fileUrl: response.data.fileUrl })
        })
        .catch(e => {
          commit('SET_UPLOAD_ERROR', 'Failed to get presigned URL')
          reject(e)
        })
      })
    },
    async uploadFile({ commit, dispatch }, { file, folder }) {
      return new Promise((resolve, reject) => {
        // Reset error state before uploading
        commit('SET_UPLOAD_ERROR', null);

        // Fetch presigned URL for this file
        dispatch('getPresignedUrl', { 
          name: file.name, type: file.type, folder 
        })
        .then(({ url, fileUrl }) => {
          const options = {
            headers: {
              'Content-Type': file.type
            },
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              commit('SET_UPLOAD_PROGRESS', { fileName: file.name, progress });
            },
          };

          // Upload file to S3 using the presigned URL
          axios.put(url, file, options)
            .then(() => {
              commit('ADD_UPLOADED_FILE', file.name)
              resolve({ fileUrl })
            })
            .catch(e => {
              commit('SET_UPLOAD_ERROR', 'Failed to upload file');
              reject(e)
            })
        })
      })
    },
    clearUploads({ commit }) {
      commit('CLEAR_UPLOADS');
    },
  },
  getters: {
    uploadProgress: (state) => (fileName) => {
      return state.uploadProgress[fileName] || 0;
    },
    uploadError: (state) => state.uploadError,
    filesUploaded: (state) => state.filesUploaded,
  },
};

export default fileUpload;