import a from '@/utils/axios/axios'
// import Vue from 'vue';

const batch = {
    namespaced: true,
    state: () => ({
        selectedItems: []
    }),
    mutations: {    
        ADD_ITEM(state, item) {
            if (!state.selectedItems.includes(item)) {
              state.selectedItems.push(item);
            }
          },
          REMOVE_ITEM(state, item) {
            state.selectedItems = state.selectedItems.filter(i => i !== item);
          },
          CLEAR_ITEMS(state) {
            state.selectedItems = [];
          }
    },
    actions: {
        addItem({ commit }, item) {
            commit('ADD_ITEM', item);
        },
        removeItem({ commit }, item) {
            commit('REMOVE_ITEM', item);
        },
        clearItems({ commit }) {
            commit('CLEAR_ITEMS');
        },
        deleteSelectedItems({ commit, dispatch, state }) {
            return new Promise((resolve, reject) => {
                const clipIds = state.selectedItems.filter(item => !item.total_in_seconds).map(item => item.id);
                const timeIds = state.selectedItems.filter(item => item.total_in_seconds).map(item => item.id);

                let promises = [];

                if (clipIds && clipIds.length > 0) {
                    promises.push(a.delete('clip/clips', { data: { ids: clipIds } }));
                }

                if (timeIds && timeIds.length > 0) {
                    promises.push(a.delete('time/times', { data: { ids: timeIds } }));
                }

                Promise.all(promises)
                .then(responses => {
                    resolve(responses);
                    dispatch('clearItems')
                })
                .catch(err => {
                    reject(err);
                });
                for(let id of clipIds) {
                    commit('videos/removeClip', id, { root: true })
                }
                for(let id of timeIds) {
                    commit('videos/removeTime', id, { root: true })
                }
            });
        },
        batchAddItems({ dispatch,  state },  parent_id ) {
            return new Promise((resolve, reject) => {
                const addFolderPromises = state.selectedItems.map(item => {
                    const folderPayload = {
                        name: item.title || 'No title',
                        parent: parent_id,
                        type: Object.prototype.hasOwnProperty.call(item, 'total_in_seconds') ? 'time' : 'clip',
                    };

                    if(folderPayload.type === 'time') folderPayload.time_id = item.id
                    else folderPayload.clip_id = item.id

                    return dispatch('folder/addFolder', folderPayload, { root: true });
                });

                Promise.all(addFolderPromises)
                    .then(responses => {
                        resolve(responses);
                        dispatch('clearItems')
                    })
                    .catch(err => {
                        reject(err);
                    })
            });
        }
    },
    getters: {
        selectedItems: (state) => state.selectedItems
    }
}

export default batch;