import a from '@/utils/axios/axios'

const auth = {
  namespaced: true,
  state: () => ({
    rules: [],
    emptyRule: {
      if_rule: [],
      then_rule: [],
      when_rule: []
    }
  }),
  mutations: {
    setRules(state, payload) {
      state.rules = payload
    },
    addEmptyRule(state) {
      state.rules = [...state.rules].concat(state.emptyRule)
    },
    addRule(state, rule) {
      state.rules = state.rules.concat(rule)
    },
    removeRule(state, id) {
      state.rules = state.rules.filter(e => e.id != id)
    },
    editRule(state, rule) {
      let mod = [...state.rules]
      let i = mod.findIndex(e => e.id == rule.id)
      mod[i] = rule

      state.rules = mod
    }
  },
  actions: {
    initRules({ commit, rootGetters }) {
      const team_id = rootGetters['user/currentTeamId']
      return new Promise((resolve, reject) => {
        a.get(`/rule/team/${team_id}`)
          .then(res => {
            commit('setRules', res.data)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    saveRule({ rootGetters, commit }, rule) {
      const team_id = rootGetters['user/currentTeamId']
      return new Promise((resolve, reject) => {
        a.post(`/rule/team/${team_id}`, rule)
            .then(res => {
              resolve(res)
              commit('addRule', res.data)
            })
            .catch(err => {
              reject(err)
            })
      })
    },
    deleteRule({ commit }, id) {
      return new Promise((resolve, reject) => {
        a.delete(`/rule/${id}`)
            .then(res => {
              resolve(res)
              commit('removeRule', id)
            })
            .catch(err => {
              reject(err)
            })
      })
    },
    updateRule({ commit }, { name, rule, id }) {
      return new Promise((resolve, reject) => {
        a.put(`/rule/${id}`, { name, rule })
            .then(res => {
              resolve(res)
              commit('editRule', res.data)
            })
            .catch(err => {
              reject(err)
            })
      })
    },
    setActive({ commit }, { active, id }) {
      return new Promise((resolve, reject) => {
        a.put(`/rule/active/${id}`, { active })
            .then(res => {
              resolve(res)
              commit('editRule', res.data)
            })
            .catch(err => {
              reject(err)
            })
      })
    }
  },
  getters: {
    rules: state => state.rules
  }
}

export default auth