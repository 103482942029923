import a from '@/utils/axios/axios';
import AmazonIVSBroadcastClient from "amazon-ivs-web-broadcast";

const streaming = {
    namespaced: true,
    state: {
        canvasElement: null, // canvas element in Streaming.vue
        audioSources: [], // available audio sources
        videoSources: [], // available video sources
        selectedAudioSource: null, // Selected audio source
        selectedVideoSource: null, // selected video source
        streamPlaying: false, // true if broadcasting
        channelArn: null, // Active channels arn
        ingestEndPoint: null, // Active channels ingest endpoint
        streamKey: null, // active channels stream key
        ivsBroadcast: null, // IVS broadcast client instance
        mediaStream: null, // users mediaStream
        isModalOpen: false, // visibility of the stream title dialog
        permissions: false, // has user granted permission to mediaStreams
        loading: false, // If init is loading: true
        streamLoading: false, // true if start broadcast is loading
        dialogOpen: false, // Media stream permission tutorial dialogs visibility
        canvasHeight: '100%', // height of the preview canvas element
        canvasWidth: '100%', // width of the preview canvas element
        mobile: false, // true if user opens streaming from a mobile device
        rotating: false, // true when window is being repainted on mobile device rotation
        checkingStreamAvailability: false, // true when checking stream availability
        streamAvailable: false, // false, when no streamId is available, true when stream is on and id available
    },
    mutations: {
        START_STREAM(state) {
            state.streamPlaying = true
        },
        STOP_STREAM(state) {
            state.streamPlaying = false
        },
        SET_AUDIO_SOURCES(state, devices) {
            state.audioSources = devices
        },
        SET_VIDEO_SOURCES(state, devices) {
            state.videoSources = devices
        },
        SET_SELECTED_AUDIO_SOURCE(state, audioSource) {
            state.selectedAudioSource = state.audioSources.find(source => source.label == audioSource)
        },
        SET_SELECTED_VIDEO_SOURCE(state, videoSource) {
            state.selectedVideoSource = state.videoSources.find(source => source.label == videoSource)
        },
        SET_CHANNEL_ARN(state, arn) {
            state.channelArn = arn
        },
        SET_MEDIA_STREAM(state, stream) {
            state.mediaStream = stream
        },
        SET_IVS_BROADCAST(state, client) {
            state.ivsBroadcast = client
        },
        SET_INGEST_END_POINT(state, endPoint) {
            state.ingestEndPoint = endPoint
        },
        SET_STREAM_KEY(state, key) {
            state.streamKey = key
        },
        OPEN_MODAL(state) {
            state.isModalOpen = true;
        },
        CLOSE_MODAL(state) {
            state.isModalOpen = false;
        },
        SET_PERMISSIONS(state, payload) {
            state.permissions = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_STREAM_LOADING(state, payload) {
            state.streamLoading = payload
        },
        OPEN_TUTORIAL(state) {
            state.dialogOpen = true
        },
        CLOSE_TUTORIAL(state) {
            state.dialogOpen = false
        },
        SET_CANVAS_DIMENSIONS(state, payload) {
            state.canvasHeight = payload.canvasHeight
            state.canvasWidth = payload.canvasWidth
        },
        SET_MOBILE(state, payload) {
            state.mobile = payload
        },
        ROTATION_LOADING(state, payload) {
            state.rotating = payload
        },
        SET_STREAM_AVAILABLE(state, val) {
            state.streamAvailable = val
        },
        SET_CHECKING_AVAILABILITY(state, val) {
            state.checkingStreamAvailability = val
        }
    },
    actions: {

        // Init gets users mediaStream and permissions to audio and video devices
        // Init also creates an IVS broadcast client instance and attaches audio and video streams to the preview
        // canvas: Stream.vue has a canvas element where the preview is attached to
        async init({ dispatch, commit, getters}, canvas) {

            commit('SET_LOADING', true)
            const stream = await dispatch('getMediaStream')

            commit('SET_MEDIA_STREAM', stream)

            await dispatch('getDevices')

            commit('SET_SELECTED_AUDIO_SOURCE', getters.audioSources[0].label)
            commit('SET_SELECTED_VIDEO_SOURCE', getters.videoSources[0].label)

            if (getters.mediaStream && canvas) {
                const config = {
                    ingestEndpoint: '',
                    streamConfig: AmazonIVSBroadcastClient.BASIC_LANDSCAPE ,
                };

                // Create an instance of AmazonIVSBroadcastClient
                const client =  new AmazonIVSBroadcastClient.create(config)
                commit('SET_IVS_BROADCAST', client);
                getters.ivsBroadcast.attachPreview(canvas)
                dispatch('addVideo')
                dispatch('addAudio')
            }

            commit('SET_LOADING', false)
        },

        // fetches an IVS channels stream info by channel arn
        getStream({ getters }) {
            return new Promise((resolve, reject) => {
                const channelArn = getters['channelArn']
                if(!channelArn) throw new Error('No channel arn present')
                a.get(`/stream/getstream`, { params: { arn: channelArn }})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
            })
        },

        // fetches an IVS channels stream info, but does not throw error if the stream is not yet available. 
        // Also sets the state of a stream being available according to the response
        isStreamAvailable({ getters }) {
            return new Promise((resolve) => {
                const channelArn = getters['channelArn']
                if(!channelArn) throw new Error('No channel arn present')
                a.get(`/stream/getstream`, { params: { arn: channelArn }})
                .then(response => {
                    resolve(response)
                })
                .catch(() => {
                    resolve(false)
                })
            })
        },

        // Sets an interval, when backend calls to check stream availability are done.
        startStreamAvailabilityChecker({dispatch, commit}, interval = 1000 /* milliseconds */, maxDuration = 30000 /* milliseconds */) {
            commit('SET_CHECKING_AVAILABILITY', true)
            return new Promise((resolve, reject) => {
                const startTime = Date.now()
                const intervalId = setInterval(async () => {
                    const elapsedTime = Date.now() - startTime
                    if (elapsedTime >= maxDuration) {
                        clearInterval(intervalId)
                        commit('SET_STREAM_AVAILABLE', false)
                        commit('SET_CHECKING_AVAILABILITY', false)
                        reject(new Error("Stream availability check timed out."))
                        return;
                    }
                    const streamAvailable = await dispatch('isStreamAvailable')
                    if (streamAvailable) {
                        clearInterval(intervalId)
                        commit('SET_STREAM_AVAILABLE', true)
                        commit('SET_CHECKING_AVAILABILITY', false)
                        resolve(true)
                    }
                }, interval)
            })
        },
        
        // Creates an IVS Channel and starts broadcasting to it with the broadcast client instance
        // Backend handles the creation of a video object to database with a webhook
        async startStream({dispatch, commit, getters}) {
            commit('SET_STREAM_LOADING', true)
            const streamTitle = JSON.parse(window.localStorage.getItem('streamName'))
            try {
                const response = await a.post('/stream', {title: streamTitle});
            
                const arn = response.data.channel.arn;
                dispatch('setChannelArn', arn);
                dispatch('setIngestEndPoint', response.data.channel.ingestEndpoint);
                dispatch('setStreamKey', response.data.streamKey.value);
            
                await getters.ivsBroadcast.startBroadcast(response.data.streamKey.value, response.data.channel.ingestEndpoint);
            
                commit('START_STREAM');
                commit('SET_STREAM_LOADING', false)

                dispatch('startStreamAvailabilityChecker')
            
                return response;
              } catch (error) {
                console.error(error);
                commit('SET_STREAM_LOADING', false)
                dispatch('noti/error', error, { root: true});
                throw error;
              }
        },

        // Stops broadcasting through the IVS broadcast client instance
        // Deletion of the IVS channel is handled in the backend side with webhook
        stopStream({commit, getters}) {
            commit('STOP_STREAM')
            commit('SET_STREAM_AVAILABLE', false)
            if(getters.ivsBroadcast)
            getters.ivsBroadcast.stopBroadcast()
        },

        // Sets selected audio source to state and attaches it to IVS broadcast client instancet
        // Can only be done if there is no active broadcast
        // audioSource: audio sources mediaStream
        async setSelectedAudioSource({ commit, getters, dispatch }, audioSource) {
            if(getters.selectedAudioSource) await getters.ivsBroadcast.removeAudioInputDevice('microphone')

            commit('SET_SELECTED_AUDIO_SOURCE', audioSource);

            dispatch('addAudio')
        },

        // Sets selected video source to state and attaches it to IVS broadcast client instance
        // Can only be done if there is no active broadcast
        // videoSource: video sources mediaStream
        async setSelectedVideoSource({ commit, getters, dispatch }, videoSource) {
            try {
                if(getters.selectedVideoSource) await getters.ivsBroadcast.removeVideoInputDevice('camera')
            } catch(e) {
                console.log('no input device')
            }

            commit('SET_SELECTED_VIDEO_SOURCE', videoSource);

            dispatch('addVideo')
        },

        // Adds selectedAudioSource to IVS broadcast client instance
        async addAudio({getters}) {
            const audio = await navigator.mediaDevices.getUserMedia({
                audio: {deviceId: getters.selectedAudioSource.deviceId}
            })

            if (audio && audio.active) {
                await getters.ivsBroadcast.addAudioInputDevice(audio, 'microphone');
            } else {
                console.error('Selected Audio Source is not valid or closed.');
            }
        },

        // Adds selectedVideoSource to IVS broadcast client instance
        async addVideo({getters}) {
            const video = await navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: { exact: getters.selectedVideoSource.deviceId },
                    width: { ideal: 1920, max: 1920 },
                    height: { ideal: 1080, max: 1080 }
                }
            })

            if (video && video.active) {
                // Add video input device to the broadcast stream
                await getters.ivsBroadcast.addVideoInputDevice(video, 'camera', { index: 0 });
            } else {
                console.error('Selected Video Source is not valid or closed.');
            }
        },

        // Sets arn to state
        // arn: IVS channel arn
        setChannelArn({ commit }, arn) {
            commit('SET_CHANNEL_ARN', arn)
        },

        // Sets channels ingest endpoint to state
        // endPoint: channels ingest endpoint
        setIngestEndPoint({commit}, endPoint) {
            commit('SET_INGEST_END_POINT', endPoint)
        },

        // Sets channels stream key to state
        // streamKey: channels stream key
        setStreamKey({commit}, streamKey) {
            commit('SET_STREAM_KEY', streamKey)
        },

        // Gets permissions to users audio and video devices
        // return: users mediaStream
        async getMediaStream({commit}) {
            try {
              const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
              commit('SET_PERMISSIONS', true)
              // Access to audio and video devices is granted, and 'stream' contains the media stream.
              return stream;
            } catch (error) {
              // Handle errors, user may have denied permission.
              console.error('Error accessing media devices:', error);
              commit('SET_PERMISSIONS', false)
              return null;
            }
          },

        // Lists users available audio and video devices to state
        async getDevices({commit}) {

            try {
              const devices = await navigator.mediaDevices.enumerateDevices();
              commit('SET_AUDIO_SOURCES', devices.filter(device => device.kind === 'audioinput'))
              commit('SET_VIDEO_SOURCES', devices.filter(device => device.kind === 'videoinput'))
            } catch (error) {
              console.error('Error enumerating devices:', error);
            }
        },
        
        // Cheks if the user has granted permissions to their mediaStream
        async checkMediaPermissions() {
            try {
                const cameraPermission = await navigator.permissions.query({ name: 'camera' });
                const microphonePermission = await navigator.permissions.query({ name: 'microphone' });
    
                if (cameraPermission.state === 'granted' && microphonePermission.state === 'granted') {
                    return true
                }
                return false
            } catch (error) {
                console.error('Error checking media permissions:', error);
            }
        },

        // Sets stream title dialogs visibility to true
        openModal({commit}) {
            commit('OPEN_MODAL')
        },

        // Sets stream title dialogs visibility to false
        closeModal({commit}) {
            commit('CLOSE_MODAL')
        },

        // Sets given stream name to localStorage
        // name: User input from streamTitle component
        setStreamName(_, name) {
            window.localStorage.setItem('streamName', JSON.stringify(name))
        },

        // Sets permission granting tutorial dialogs visibility to true
        openTutorial({commit}) {
            commit('OPEN_TUTORIAL')
        },

        // Sets permission granting tutorial dialogs visibility to false
        closeTutorial({commit}) {
            commit('CLOSE_TUTORIAL')
        },

        // Sets a loading property, that helps with handling mobile devices rotation from portrait to landscape
        rotationLoading({commit}, payload) {
            commit('ROTATION_LOADING', payload)
        },

        // clears info for a safe exit from streaming component
        clear({dispatch, commit}) {
            dispatch('stopStream')
            commit('SET_CHECKING_AVAILABILITY', false)
        },

        // Calculates new dimensions for the canvas element that has the broadcast preview
        updateCanvasDimensions({commit}, canvas) {
            const aspectRatio = 16 / 9; // 16:9 aspect ratio
            const boundingRect = canvas.getBoundingClientRect()
            const containerWidth = boundingRect.width;
            const containerHeight = boundingRect.height;

            // // Calculate canvas size based on aspect ratio
            const widthFromHeight = containerHeight * aspectRatio;
            const heightFromWidth = containerWidth / aspectRatio;
      
            // // Determine which dimension is the limiting factor
            if (widthFromHeight <= containerWidth) {
              // Height is the limiting factor
              let canvasWidth = widthFromHeight <= containerWidth ? `${widthFromHeight}px` : `${containerWidth}px`;
              let canvasHeight = containerHeight <= containerHeight ? `${containerHeight}px` : `${containerHeight}px`;
              commit('SET_CANVAS_DIMENSIONS', {canvasWidth, canvasHeight})

            } else {
              // Width is the limiting factor
              let canvasWidth = containerWidth <= containerWidth ? `${containerWidth}px` : `${containerWidth}px`;
              let canvasHeight = heightFromWidth <= containerHeight ? `${heightFromWidth}px` : `${containerHeight}px`;
              commit('SET_CANVAS_DIMENSIONS', {canvasWidth, canvasHeight})
            }

        },

        // Sets mobile attribute according to users device dimensions
        setMobile({commit}, payload) {
            commit('SET_MOBILE', payload)
        }

    },
    getters: {
        audioSources: state => state.audioSources,
        videoSources: state => state.videoSources,
        selectedAudioSource: state => state.selectedAudioSource,
        selectedVideoSource: state => state.selectedVideoSource,
        streamPlaying: state => state.streamPlaying,
        channelArn: state => state.channelArn,
        mediaStream: state => state.mediaStream,
        ivsBroadcast: state => state.ivsBroadcast,
        ingestEndPoint: state => state.ingestEndPoint,
        streamKey: state => state.streamKey,
        isModalOpen: state => state.isModalOpen,
        permissions: state => state.permissions,
        loading: state => state.loading,
        dialogOpen: state => state.dialogOpen,
        canvasHeight: state => state.canvasHeight,
        canvasWidth: state => state.canvasWidth,
        streamLoading: state => state.streamLoading,
        mobile: state => state.mobile,
        rotating: state => state.rotating,
        streamAvailable: state => state.streamAvailable,
        checkingStreamAvailability: state => state.checkingStreamAvailability,
    }
}

export default streaming;