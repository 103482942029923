<template>
  <v-sheet
    flat
    style="margin: 10px 4px 10px 4px; text-align: center; background-color: rgba(0, 0, 0, 0);"
    :class="{'px-3' : show_sidebar}"
    class="py-3"
  >
      <span
        v-if="show_sidebar"
      >
        {{$t('upload_threshold.text')}}
      </span>
      <v-progress-linear
        class="mt-3"
        height="20"
        :value="percentage"
        striped
        rounded
      >
        <span v-if="show_sidebar">
          {{prettyUploadedHours}}h / {{this.currentTeam.total_hours_saved}}h
        </span>
      </v-progress-linear>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('user', [
      'user',
      'currentTeam'
    ]),
    ...mapGetters([
      'show_sidebar'
    ]),
    ...mapGetters('videos', [
      'uploaded'
    ]),
    percentage() {
      if(!this.uploaded) return 0
      if(!this.currentTeam) return 0
      return this.uploaded / (this.currentTeam.total_hours_saved * 60 * 60) * 100
    },
    prettyPercentage() {
      return parseInt(this.percentage) + '%'
    },
    prettyUploadedHours() {
      return Math.floor(this.uploaded / 60 / 60)
    }
  }
}
</script>