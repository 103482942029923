import a from '@/utils/axios/axios'

const filter = {
  namespaced: true,
  state: () => ({
    filters: [],
    filter: null,
    loading: false,
    events: [],
    openedEvent: null,
    buffer: 0,
    end_buffer: 0,
    event: null
  }),
  mutations: {
    ADD_FILTER(state, filter) {
      state.filters = [...state.filters].concat(filter)
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    SET_FILTER(state, filter) {
      let mod = [...state.filters]
      let i = mod.findIndex(t => t.id == filter.id)

      mod[i] = filter

      state.filters = mod
    },
    REMOVE_FILTER(state, id) {
      state.filters = [...state.filters].filter(f => f.id != id)
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_EVENTS(state, events) {
      state.events = events
    },
    SET_OPENED_EVENT(state, event) {
      state.openedEvent = event
    },
    SET_BUFFER(state, buffer) {
      state.buffer = buffer
    },
    SET_CURRENT_FILTER(state, filter) {
      state.filter = filter
    },
    SET_END_BUFFER(state, value) {
      state.end_buffer = value
    },
    SET_PLAYING_EVENT(state, event) {
      state.event = event
    }
  },
  actions: {
    initFilters({ commit, rootGetters }) {
      const team_id = rootGetters['user/currentTeamId']
      return new Promise((resolve, reject) => {
        a.get(`/filter/team/${team_id}`)
          .then(res => {
            commit('SET_FILTERS', res.data)
            resolve(res)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    updateTitle({ commit, rootGetters }, filter) {
      filter['team_id'] = rootGetters['user/currentTeamId']

      return new Promise((resolve, reject) => {
        a.put(`/filter/${filter.id}`, filter)
          .then(res => {
            commit('SET_FILTER', res.data)
            resolve(res)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    deleteFilter({ commit }, id) {
      return new Promise((resolve, reject) => {
        a.delete(`/filter/${id}`)
          .then(res => {
            commit('REMOVE_FILTER', id)
            resolve(res)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    postFilter({ commit, rootGetters }, filter) {
      filter['team_id'] = rootGetters['user/currentTeamId']
      return new Promise((resolve, reject) => {
        a.post('/filter', filter)
          .then(res => {
            commit('ADD_FILTER', res.data)
            resolve(res)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    getFilterData({ commit }, { id, index, limit }) {
      commit('SET_LOADING', true)
      return new Promise((resolve, reject) => {
        a.get(`/filter/${id}/clip?index=${index}&limit=${limit}`)
          .then(e => {
            resolve(e.data)
            commit('SET_EVENTS', e.data.clips)
            commit('SET_CURRENT_FILTER', e.data.filter)
          })
          .catch(e => reject(e))
          .finally(() => {
            commit('SET_LOADING', false)
          })
      })
    },
    getFilterDataNoSave({ commit }, { filter, index, limit }) {
      commit('SET_LOADING', true)
      return new Promise((resolve, reject) => {
        a.get(`/filter/nosave/clip?index=${index}&limit=${limit}&filter=${filter}&nosave=true}`)
          .then(e => {
            resolve(e.data)
            commit('SET_EVENTS', e.data.clips)
            commit('SET_CURRENT_FILTER', e.data.filter)
          })
          .catch(e => reject(e))
          .finally(() => {
            commit('SET_LOADING', false)
          })
      })
    }
  },
  getters: {
    filters: state => state.filters,
    loading: state => state.loading,
    events: state => state.events,
    openedEvent: state => state.openedEvent,
    buffer: state => state.buffer,
    end_buffer: state => state.end_buffer,
    eventsFlat: (state, getters) => {
      return getters.events.map(v => {
        var video = {...v}
        delete video.clips
        return v.clips.map(clip => {
          return { ...clip, video }
        })
      }).flat()
    },
    eventById: (state, getters) => id => {
      return getters.eventsFlat.find(e => e.id == id)
    },
    filter: state => state.filter,
    event: state => state.event
  }
}

export default filter