import { mapGetters } from 'vuex'

export default {
  methods: {
    track(event, data) { // event.event: "Opened video"
      if(!event) return
      this.$mixpanel.track(event, {
        distinct_id: this.user?.email,
        mobile_app: this.isApp,
        current_team_name: this.currentTeam?.team_name,
        current_team_id: this.currentTeam?.id,
        team_orderer: this.isOrderer,
        team_admin: this.isAdmin,
        plan_id: this.currentPlanId,
        ...data
      })

    }
  },
  computed: {
    ...mapGetters('user', [
      'user',
      'currentTeam',
      'current_sport_id',
      'isOrderer',
      'isAdmin',
      'plan_id'
    ]),
    isApp() {
      let standalone = window.navigator.standalone
      let userAgent = window.navigator.userAgent.toLowerCase()
      let safari = /safari/.test(userAgent)
      let ios = /iphone|ipod|ipad/.test(userAgent)

      if (ios) {
        if (!standalone && safari) {
          // Safari
          return false
        } else if (!standalone && !safari) {
          // iOS webview
          return true
        }
      } else {
        if (userAgent.includes('wv')) {
          return true
          // Android webview
        } else {
          return false
          // Chrome
        }
      }

      return false
    }
  }
}