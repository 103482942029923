import a from "@/utils/axios/axios";
import axios from 'axios';

const upload = {
  namespaced: true,
  state: () => ({
    uploadProgress: {},
    overallProgress: 0,
    uploadingImages: false
  }),
  mutations: {
    setUploadProgress(state, { fileName, progress }) {
      state.uploadProgress = { ...state.uploadProgress, [fileName]: progress };
      // Recalculate overallProgress each time an uploadProgress is updated
      const totalProgress = Object.values(state.uploadProgress).reduce((total, current) => total + current, 0);
      state.overallProgress = totalProgress / Object.keys(state.uploadProgress).length;
    },
    resetUploadProgress(state) {
      state.uploadProgress = {};
      state.overallProgress = 0;
    },
    SET_UPLOADING_IMAGES(state, val) {
      state.uploadingImages = val
    }
  },
  actions: {
    async uploadImage({ commit }, { imageData, fileName, clip_id, layer_id }) {
      const response = await a.get('/export/presigned-url', {
        params: {
          fileName,
          fileType: 'image/png',
          clip_id,
          layer_id
        },
      });

      const { signedRequest, url } = response.data;
      const blob = await (await fetch(imageData)).blob();

      try {
        await axios.put(signedRequest, blob, {
          headers: {
            'Content-Type': 'image/png',
          },
          onUploadProgress: (progressEvent) => {
            const progress = parseInt((progressEvent.loaded / progressEvent.total) * 100);
            commit('setUploadProgress', { fileName, progress });
          },
        });
      } catch(e) {
        throw new Error(e)
      }

      return url;
    },

    async uploadImages({ commit, dispatch }, { images, clip_id }) {
      if(!images || images.length < 1) return
      commit('resetUploadProgress'); // Reset progress before starting new uploads
      commit('SET_UPLOADING_IMAGES', true)
      const uploadPromises = images.map((layer, index) => {
        const { layer_id, image_data_url } = layer
        const fileName = `clip_graphics/${layer_id}:${clip_id}:image_${Date.now()}_${index}.png`;
        return dispatch('uploadImage', { imageData: image_data_url, fileName, clip_id, layer_id });
      });

      return Promise.all(uploadPromises).then((urls) => {
        commit('SET_UPLOADING_IMAGES', false)
        return urls;
      });
    },

    startClipProcessing(_, payload) {
      return new Promise((resolve, reject) => {
        a.post('/export/start-clip-processing', payload)
          .then(e => {
            resolve(e)
          })
          .catch(e => {
            reject(e)
          })
      })
    }
  },
  getters: {
    overallProgress: state => state.overallProgress,
    uploadingImages: state => state.uploadingImages
  }
}; 

export default upload;