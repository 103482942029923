import a from '@/utils/axios/axios'

const auth = {
  namespaced: true,
  state: () => ({
    token: localStorage.getItem('tiimi-token') || '',
    user: null,
    login_loading: false,
    plans: []
  }),
  mutations: {
    setFilter(state, payload) {
      state.filter = payload
    },
    setToken(state, token) {
      state.token = token
    },
    setPlans(state, plans) {
      state.plans = plans
    }
  },
  actions: {
    login(_, payload) {
      let teamId = window.localStorage.getItem('tiimio-latest-team')
      return new Promise((resolve, reject) => {
        a.post(`/auth/login?currentTeamId=${teamId}`, payload)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    loginToDemo() {
      let teamId = window.localStorage.getItem('tiimio-latest-team')
      return new Promise((resolve, reject) => {
        a.post(`/auth/demo/login?currentTeamId=${teamId}`)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    loginToDivariDemo() {
      let teamId = window.localStorage.getItem('tiimio-latest-team')
      return new Promise((resolve, reject) => {
        a.post(`/auth/divaridemo/login?currentTeamId=${teamId}`)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    signin(_, payload) {
      return new Promise((resolve, reject) => {
        a.post('/auth/signin', payload)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            // commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    refetchToken(_, teamId) {
      teamId = teamId || window.localStorage.getItem('tiimio-latest-team')
      return new Promise((resolve, reject) => {
        a.get(`/auth/refresh?currentTeamId=${teamId}`)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    initPlans({ commit }) {
      return new Promise((resolve, reject) => {
        a.get('/plan')
          .then(res => {
            commit('setPlans', res.data)
            resolve(res)
          })
          .catch(err => {
            // commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    stripeSessionById(_, id) {
      return new Promise((resolve, reject) => {
        a.get(`/stripe/session/${id}`)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getReset({ dispatch }, {code, passwrd, passwrd_again}) {
      return new Promise((resolve, reject) => {
        a.post('/auth/change-password/code', {
          token: code,
          passwrd,
          passwrd_again
        })
        .then(e => {
          dispatch('user/initUser', e.data.token, { root: true })
          console.log(e)
          resolve(e.data)
        })
        .catch(e => reject(e))
      }) 
    },
    orderLink(_, email) {
      return new Promise((resolve, reject) => [
        a.post(`/auth/forgot-password/${email}`)
          .then(e => resolve(e))
          .catch(e => reject(e))
      ])
    },
    confirmEmail(_, code) {
      return new Promise((resolve, reject) => {
        a.post('/auth/confirm-email', { code })
          .then(e => resolve(e.data))
          .catch(e => reject(e))
      })
    }
  },
  getters: {
    token: state => state.token,
    plans: state => state.plans ? state.plans.sort((a, b) => a.price - b.price) : [],
  }
}

export default auth