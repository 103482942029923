import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueKeybindings from 'vue-keybindings'
import i18n from './i18n'
import vSelect from 'vue-select'
import VueGtag from "vue-gtag";
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import 'vue-select/dist/vue-select.css';
import VDragged from 'v-dragged'
import ProductFruits from '@/utils/onboarding/ProductFruits.js'
import browserDetect from "vue-browser-detect-plugin";
import VueMixpanel from 'vue-mixpanel'
import mixpanel from '@/mixins/Mixpanel.js'
import VueConfetti from 'vue-confetti'

import {
  // Directives
  VTooltip
} from 'floating-vue'
import VueKonva from 'vue-konva';

Vue.use(VueConfetti)
Vue.use(VueKonva);
Vue.use(browserDetect);
Vue.directive('tooltip', VTooltip)
Vue.component('ProductFruits', ProductFruits)
Vue.use(VDragged)

Vue.use(VueMixpanel, {
  token: '5019c6050fb142361c60b8dbfb9842c7',
  config: {
    debug: true
  }
})

Vue.config.productionTip = false

Vue.use(VueKeybindings, {
    alias: {
        toggle: ['space'],

        skipForwardLarge: 'shift+arrowright',
        skipForward: ['arrowright'],
        skipForwardSmall: 'alt+arrowright',

        skipBackwardLarge: 'shift+arrowleft',
        skipBackward: ['arrowleft'],
        skipBackwardSmall: 'alt+arrowleft',

        escape: 'esc',

        rateDown: ['arrowdown'],
        rateUp: ['arrowup'],

        enter: ['enter'],
        
        w: ['w'],
        d: ['d'],
        t: ['t'],
    }
})

var prevent_keys = Vue.directive('prevent-keys', {
  bind: function(el) {
    el.addEventListener('focusin', () => {
      store.commit('DISABLE_KEYBINDS')
    });

    el.addEventListener('focusout', () => {
      store.commit('ENABLE_KEYBINDS')
    });
  }
})

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.use(prevent_keys)
Vue.component('vue-select', vSelect)

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "G-LK8Q1JL75S" }
}, router);

Vue.mixin(mixpanel)

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')