import a from '@/utils/axios/axios'

const noti = {
  namespaced: true,
  state: () => ({
    maps: [],
    map_bases: []
  }),
  mutations: {
    ADD_MAP(state, map) {
      state.maps = state.maps.concat(map)
    },
    SET_MAPS(state, maps) {
      state.maps = maps
    },
    PUT_MAP(state, map) {
      console.log(map)
      let mod = state.maps
      console.log(mod)
      let i = mod.findIndex(e => e.id == map.id)

      mod[i] = map

      state.maps = mod
    },
    REMOVE_MAP(state, id) {
      let mod = state.maps
      mod = mod.filter(e => e.id != id)

      state.maps = mod
    },
    SET_MAP_BASES(state, bases) {
      state.map_bases = bases
    }

  },
  actions: {
    postMap({ commit, rootGetters } , map) {
      const teamId = rootGetters['user/currentTeamId']

      return new Promise((resolve, reject) => {
        a.post('/maps', { ...map, team_id: teamId })
          .then(e => {
            resolve(e.data)
            commit('ADD_MAP', e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
        
    },
    initMaps({ commit, rootGetters }) {
      const teamId = rootGetters['user/currentTeamId']
      return new Promise((resolve, reject) => {
        a.get(`/maps/team/${teamId}`)
          .then(e => {
            resolve(e.data)
            commit('SET_MAPS', e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    initSportMapBases({ commit, rootGetters }, sport_id) {
      sport_id = sport_id || rootGetters['template/sport_id']
      return new Promise((resolve, reject) => {
        a.get(`/maps/base/sport/${sport_id}`)
          .then(e => {
            resolve(e.data)
            commit('SET_MAP_BASES', e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    getMap(_, { id }) {
      return new Promise((resolve, reject) => {
        a.get(`/maps/${id}`)
          .then(e => {
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    editMap({ commit }, { id, title, description, points }) {
      return new Promise((resolve, reject) => {
        a.put(`/maps/${id}`, { title, description, points })
          .then(e => {
            commit('PUT_MAP', e.data)
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    deleteMap({ commit }, id) {
      return new Promise((resolve, reject) => {
        a.delete(`/maps/${id}`)
          .then(e => {
            commit('REMOVE_MAP', id)
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    getMapBases({ commit, rootGetters }) {
      const team_id = rootGetters['user/currentTeamId']
      return new Promise((resolve,reject) => {
        a.get(`/maps/base/team/${team_id}`)
          .then(e => {
            commit('SET_MAP_BASES', e.data)
            resolve(e)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })

      })
    }
  },
  getters: {
    maps: state => state.maps,
    map_bases: state => state.map_bases
  }
}

export default noti