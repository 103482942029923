import a from "@/utils/axios/axios";

const clip = {
  namespaced: true,
  state: () => ({
    folders: [],
    orderBy: "created", // created || name
    openedFolderId: null,
    activeFolderId: null,
    route: [], // Route holds an array of the parents of current folder
    loadingClips: false,
  }),
  mutations: {
    SET_FOLDERS(state, payload) {
      state.folders = [...payload];
    },
    UPDATE_FOLDER_ORDER(state, folder_ids) {
      let mod = [...state.folders];

      folder_ids.forEach((id) => {
        const fold = mod.find((f) => f.id == id);
        mod = mod.filter((f) => f.id != id);
        mod.push(fold);
      });

      state.folders = mod;
    },
    ADD_FOLDER(state, payload) {
      state.folders = [payload].concat(state.folders);
    },
    ADD_FOLDERS(state, payload) {
      let mod = [...state.folders];
      let ids = mod.map((f) => f.id);
      let unique = payload.filter((f) => !ids.includes(f.id));

      state.folders = [...state.folders].concat(unique);
    },
    SET_ACTIVE_FOLDER_ID(state, payload) {
      state.activeFolderId = payload;
    },
    OPEN_FOLDER(state, payload) {
      state.openedFolderId = payload;
    },
    SET_FOLDER(state, folder) {
      let mod = [...state.folders];
      let index = mod.findIndex((f) => f.id == folder.id);
      if (index < 0) return;
      mod[index] = folder;
      state.folders = mod;
    },
    REMOVE_FOLDER(state, id) {
      state.folders = [...state.folders].filter((f) => f.id != id);
    },
    SET_LOADING_CLIPS(state, is) {
      state.loadingClips = is;
    },
  },
  actions: {
    setActiveFolder(context, payload) {
      context.commit("SET_ACTIVE_FOLDER_ID", payload);
    },

    getFolders({ commit, rootGetters }, parent_id) {
      const teamId = rootGetters["user/currentTeamId"];
      if (!parent_id) parent_id = "root";
      return new Promise((resolve, reject) => {
        a.get(`/folder/team/${teamId}/parent/${parent_id}`)
          .then((res) => {
            commit("ADD_FOLDERS", res.data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    batchAddClips({ commit, rootGetters }, { clips, parent_id }) {
      const team_id = rootGetters["user/currentTeamId"];

      return new Promise((resolve, reject) => {
        a.post(`/folder/clip-batch`, { team_id, parent_id, clips })
          .then((res) => {
            commit("SET_FOLDERS", res.data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getVideoClips({ rootGetters }) {
      const teamId = rootGetters["user/currentTeamId"];

      return new Promise((resolve, reject) => {
        a.get(`/clip/team/${teamId}`)
          .then((res) => resolve(res.data.videos))
          .catch((err) => reject(err));
      });
    },
    updateFolderOrder({ commit, rootGetters }, folders) {
      const team_id = rootGetters["user/currentTeamId"];
      commit("UPDATE_FOLDER_ORDER", folders);
      return new Promise((resolve, reject) => {
        a.put(`/folder/order`, { team_id, folders })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getVideoTimes({ rootGetters }) {
      const teamId = rootGetters["user/currentTeamId"];

      return new Promise((resolve, reject) => {
        a.get(`/time/team/${teamId}`)
          .then((res) => resolve(res.data.videos))
          .catch((err) => reject(err));
      });
    },
    getAllFolders({ commit, rootGetters }) {
      const teamId = rootGetters["user/currentTeamId"];
      return new Promise((resolve, reject) => {
        a.get(`/folder/team/${teamId}}`)
          .then((res) => {
            commit("SET_FOLDERS", res.data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    addFolder({ commit, getters, rootGetters }, payload) {
      const folder = {
        ...payload,
        team_id: rootGetters["user/currentTeamId"],
        parent: payload.parent || getters["openedFolderId"],
      };

      return new Promise((resolve, reject) => {
        a.post(`/folder`, { folder })
          .then((res) => {
            commit("ADD_FOLDER", res.data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateFolder({ commit }, folder) {
      if (!folder?.name || !folder?.id) return;
      return new Promise((resolve, reject) => {
        a.put(`/folder/${folder.id}`, { folder })
          .then((res) => {
            commit("SET_FOLDER", res.data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    addClipToFolder(_, { clip_id, folder_id }) {
      if (!clip_id) throw new Error("bad params");

      return new Promise((resolve, reject) => {
        a.post(`/clip/${clip_id}/folder/${folder_id}`)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    folderClips({ commit, getters }, folder_id) {
      commit("SET_LOADING_CLIPS", true);
      return new Promise((resolve, reject) => {
        a.get(`/folder/${folder_id}/clip`)
          .then((res) => {
            let folder = { ...getters.folderById(folder_id) };
            folder["clips"] = res.data;
            commit("SET_FOLDER", folder);

            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit("SET_LOADING_CLIPS", false);
          });
      });
    },
    deleteFolder({ commit }, id) {
      if (!id) return;
      return new Promise((resolve, reject) => {
        a.delete(`/folder/${id}`)
          .then((res) => {
            commit("REMOVE_FOLDER", res.data.id);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    openFolder({ commit }, id) {
      commit("OPEN_FOLDER", id);
    },
  },
  getters: {
    folders: (state, getters) => {
      if (state.openedFolderId)
        return state.folders.filter((f) => f.parent == getters.openedFolderId);
      return state.folders.filter((f) => !f.parent);
    },
    children: (state) => (id) => {
      let folders = state.folders;
      return folders.filter((f) => f.parent == id && f.type === "folder");
    },

    // Returns the type and id of the first child in the folder.
    firstChild: (state) => (id) => {
      let folders = state.folders;
      let children = folders.filter((f) => f.parent == id);
      for (let child of children) {
        switch (child.type) {
           case "folder":
            return { type: child.type, id: child.id};
          default:
            return { type: child.type, id: child[child.type + "_id"], folderId: child.id };
        }
      }
      return null;
    },
    firstChildFolderId: (state) => (id) => {
      let folders = state.folders;
      let children = folders.filter((f) => f.parent == id);
      for (let child of children) {
        return child.id;
      }
      return null;
    },
    rootLevel: (state) => {
      let folders = state.folders;
      return folders.filter((f) => !f.parent && f.type === "folder");
    },
    structuredFolders: (_state, getters) => {
      const rootLevel = getters.rootLevel;

      return rootLevel.map((f) => {
        return {
          ...f,
          children: getters.getChildren(f.id),
        };
      });
    },
    getChildren: (state, getters) => (id) => {
      if (!id) return null;
      let folder = getters.folderById(id);

      if (!folder) return null;

      let children = state.folders.filter((f) => f.parent == id);

      return children.map((child) => {
        return {
          ...child,
          children: getters.getChildren(child.id),
        };
      });
    },
    openedFolderId: (state) => state.openedFolderId,

    openedFolder: (_state, getters) => 
      getters.folderById(getters.openedFolderId),
  

    activeFolderId: (state) => state.activeFolderId,

    folderById: (state) => (id) => {
      if (!id) return undefined;
      return state.folders?.find((f) => f.id == id);
    },
    route: (_state, getters) => {
      let route = getters.openedFolder ? [getters.openedFolder] : [];
      let parentId = getters.openedFolder?.parent;

      while (parentId) {
        let full_parent = getters.folderById(parentId);
        route = [full_parent].concat(route);
        parentId = full_parent.parent;
      }

      return route.map((f) => {
        return { id: f.id, name: f.name };
      });
    },
    clips: (_, getters) => {
      return getters.openedFolder?.clips;
    },
    loadingClips: (state) => state.loadingClips,
  },
};

export default clip;
