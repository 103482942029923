import a from '@/utils/axios/axios'

const team = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    getShareLink({ rootGetters }, { resource_type, resource_id, valid_days }) {
      const team_id = rootGetters['user/currentTeamId']

      return new Promise((resolve, reject) => {
        a.post('/share', { resource_type, valid_days, resource_id, team_id })
          .then((e) => {
            resolve(e.data)
          })
          .catch(e => reject(e))
      })
    },
    fetchShareData(_, code) {
      return new Promise((resolve, reject) => {
        a.get(`/share/${code}`)
          .then(e => {
            resolve(e.data)
          })
          .catch(e => reject(e))
      })
    }
  },
  getters: {
  }
}

export default team