<template>
  <div class="pa-2" v-if="(!isApp && isOrderer && currentTeam && !currentTeam.is_the_best) || currentTeamId == 119">
    <v-btn
      block
      loading
      v-if="loading"
    ></v-btn>
    <v-tooltip color="primary" v-else-if="isMini && !expanded" right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
          v-bind="attrs"
          v-on="on"
          class="px-0" 
          block
          @click="clicked()"
        >
          <v-icon v-if="!isApp" color="primary" class="ml-0">mdi-diamond-stone</v-icon>
          <v-icon v-else color="primary" class="ml-0">mdi-cog</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('upgrade') }}</span>
    </v-tooltip>
    <v-btn v-else class="px-0" block @click="clicked()">
      {{ $t('upgrade') }}
      <v-icon v-if="!isApp" color="primary" class="ml-3">mdi-diamond-stone</v-icon>
      <v-icon v-else color="primary" class="ml-3">mdi-cog</v-icon>    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: ['expanded'],
  data: () => ({
    loading: false
  }),
  methods: {
    ...mapActions('user', [
      'openCustomerPortal'
    ]),
    clicked() {
      if(!this.isAndroid) {
        this.$router.push('/plans')
      } else {
        window?.ReactNativeWebView?.postMessage("go_to_plans");
        document?.ReactNativeWebView?.postMessage("go_to_plans");
      }
    }
  },
  computed: {
    isMini() {
      return ( !this.show_sidebar || this.$route.meta.mini ) && !this.$vuetify.breakpoint.mobile
    },
    ...mapGetters([
      'show_sidebar',
      'isApp',
      'isAndroid',
      'isIos'
    ]),
    ...mapGetters('user', [
      'currentPlanId',
      'isOrderer',
      'currentTeam',
      'currentTeamId',
      'user'
    ])
  }
}
</script>