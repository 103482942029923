// import a from '@/utils/axios/axios'

const data = {
  namespaced: true,
  state: () => ({
    clips: [],
    loading: false,
    current_filter: null
  }),
  mutations: {
    SET_DATA_CLIPS(state, val) {
      state.clips = val
    },
    SET_LOADING(state, val) {
      state.loading = val
    },
    SET_CURRENT_FILTER(state, val) {
      state.current_filter = val
    }
  },
  actions: {

  },
  getters: {
    clips: state => state.clips,
    loading: state => state.loading,
    current_filter: state => state.current_filter,
    all_tags: (state, getters) => {
      return getters.clips.map(c => c.tags).flat()
    },
    clips_by_tag_id: (_, getters) => tag_id => {
      return getters.clips.filter(c => c.tags.map(t => t.id).includes(tag_id))
    }
  }
}

export default data