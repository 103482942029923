<template>
  <v-snackbar
    style="max-width: 4000px !important;"
    v-model="show"
    id="snack"
    :timeout="-1"
  >
    <v-bottom-navigation style="height: 80px; width: 100wv;">
      <v-btn v-for="item in mobileItems" :key="item.text" value="" @click="$router.push(item.path)">
        <span>{{item.text}}</span>
        <v-icon>{{item.icon}}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-snackbar>
</template>

<script>
import { mdiVideo, mdiFolder, mdiTag, mdiTimer, mdiDotsHexagon, mdiDiamondStone } from "@mdi/js";
import { mapGetters } from 'vuex';
export default {
  props: ['items'],
  icons: { mdiVideo, mdiFolder, mdiTag, mdiTimer, mdiDotsHexagon, mdiDiamondStone },
  data: () => ({
    show: true
  }),
  computed: {
    mobileItems() {
      let it = this.items.filter(item => !item.sideonly)
      it = this.items.filter(item => !item.no_mobile)

      if(!this.inLeague) it = it.filter(item => item.path != '/games')
      if(this.timesDisabled) it = it.filter(item => item.path != '/times')

      return it
    },
    ...mapGetters('user', [
      'timesDisabled',
      'isAdmin',
      'inLeague',
    ])
  }
}
</script>

<style lang="scss">
  #snack {
    width: 100vw;
    
    .v-snack__wrapper {
      max-width: none;
      z-index: 10 !important;
      border-radius: 10px;
      width: 100vw;
      display: block;
      margin: 0;
      background-color: rgba(0, 0, 0, 0);
      border-radius: 10px;

      .v-snack__content {
        width: 100%;
        padding: 0;
      }
    }
  }
</style>