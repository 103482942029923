import a from '@/utils/axios/axios'
import Vue from 'vue'

const videos = {
  namespaced: true,
  state: () => ({
    videos: [
    ],
    show_side_bar: true,
    video: null,
    filter: '',
    loading: true,
    show_timeline: false,
    uploaded: 0 // amount of video uploaded this month in seconds per current team
  }),
  mutations: {
    setFilter(state, payload) {
      state.filter = payload
    },
    setShowTimeline(state, val) {
      state.show_timeline = val
    },
    setLoading(state, boolean) {
      state.loading = boolean
    },
    setVideos(state, payload) {
      state.videos = payload
    },
    setVideo(state, payload) {
      state.video = payload
    },
    addClipToVideo(state, { clip }) {
      let vid = state.video;
      vid['clips'] = [clip].concat(vid['clips'])
      state.video = vid
    },
    addTimeToVideo(state, { time }) {
      let vid = state.video;
      vid['times'] = vid['times'].concat(time)
      state.video = vid
    },
    updateVideoTime(state, time) {
      let mod = state.video.times
      let index = mod.findIndex(t => t.id === time.id)

      mod[index] = time

      state.video = {
        ...state.video,
        times: mod
      }
    },
    updateVideoClip(state, clip) {
      let mod = state.video.clips
      let index = mod.findIndex(t => t.id === clip.id)
      mod[index] = clip
      state.video = {
        ...state.video,
        clips: mod
      }
    },
    addVideo(state, video) {
      let mod = [...state.videos]
      mod = [video].concat(mod)

      state.videos = mod
    },
    setVideoEncodingPercentage(state, { id, percentage }) {
      let all = state.videos;
      let index = all.findIndex(vid => vid.id == id)
      all[index]['encoding_percentage'] = percentage
      state.videos = all
    },
    putVideo(state, video) {
      let mod = [...state.videos]

      let i = mod.findIndex(v => v.id == video.id)

      mod[i] = video

      state.videos = mod
    },
    updateVideos(state, video) {
      let i = state.videos.findIndex(e => e.id ==  video.id)
      let mod = [...state.videos]

      mod[i] = video

      state.videos = mod
    },
    setUploaded(state, payload) {
      state.uploaded = payload
    },
    addUploaded(state, amount) {
      state.uploaded = state.uploaded + amount
    },
    removeClip(state, id) {
      let modClips = [...state.video.clips] || []
      modClips = modClips.filter(e => e.id != id)

      state.video = {
        ...state.video,
        clips: modClips
      }
    },
    removeTime(state, id) {
      let modTimes = [...state.video.times] || []
      modTimes = modTimes.filter(e => e.id != id)

      state.video = {
        ...state.video,
        times: modTimes
      }
    },
    removeVideo(state, id) {
      state.videos = [...state.videos].filter(e => e.id != id)
    },
    setShowSideBar(state, value) {
      state.show_side_bar = value
    }
  },
  actions: {
    toggleSideBar({ commit, getters }, value) {
      if(value === undefined) commit('setShowSideBar', !getters.show_side_bar)
      else commit('setShowSideBar', value)

      Vue.nextTick(() => {
        commit('draw/UPDATE_SCALE', {}, { root: true })
      })
    },
    initVideos({ commit, getters, rootGetters }, team_id) {
      team_id = team_id || rootGetters['user/currentTeamId']
      if(!getters.videos || !getters.videos.length) {
        commit('setLoading', true)
      }
      return new Promise((resolve, reject) => {
        a.get(`/video/team/${team_id}`, team_id)
          .then(res => {
            // res.data.videos.forEach(vid => {
            //   if(!vid.encoded) (
            //     dispatch('pollVideoProgress', vid)
            //   )
            // })
            commit('setVideos', res.data.videos)
            commit('setUploaded', res.data.total)
            resolve(res.data.videos)
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
          .finally(() => {
            commit('setLoading', false)
          })
      })
    },
    deleteVideo({ commit }, id) {
      return new Promise((resolve, reject) => {
        a.delete(`/video/${id}`)
        .then(res => {
            commit('removeVideo', id)
            resolve(res)

            this.track('Video deleted', {
              id
            })
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    postVideo(_, payload) {
      return new Promise((resolve, reject) => {
        a.post('/video', payload)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateVideo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        a.put(`/video/${payload.id}`, payload)
          .then(res => {
            resolve(res)
            commit('updateVideos', res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getYtUrl(_, url) {
      return new Promise((resolve, reject) => {
        a.put(`/video/yt`, { url })
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    videoById({ commit }, video_id) {
      return new Promise((resolve, reject) => {
        a.get(`/video/${video_id}`)
          .then(res => {
            commit('setVideo', res.data)
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    gameById({ commit }, game_id) {
      return new Promise((resolve, reject) => {
        a.get(`/league/game/${game_id}`)
          .then(res => {
            commit('setVideo', res.data)
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    refreshVideoData({ dispatch, commit }, id) {
      dispatch('videoById', id)
        .then(e => {
          commit('putVideo', e.data)
        })
        .catch(err => console.log(err))
    },
    addVideoAndPoll({ commit }, video) {
      commit('addVideo', video)
    },
    pollVideoProgress({ dispatch }, video) {
      video['encoding_percentage'] = 0
      const poll_video = { ...video }
      let interval = setInterval(async () => {
        let result;
        try {
          result = await dispatch('pollEncodingStateFunction', poll_video)
        } catch(e) {
          dispatch('noti/showSnackbar', {
            text: `Something went wrong :(`,
            type: 'info'
          }, {root:true})
          clearInterval(interval)
        }
        if(result == 'clear') {
          clearInterval(interval)
          dispatch('noti/showSnackbar', {
            text: `Processing done! New video ready to watch.`,
            type: 'success'
          }, {root:true})
        }
      }, 2000)
    },
    async pollEncodingStateFunction({ commit, dispatch }, video) {
      let { data } = await a.get(`/video/${video.id}/encoding-state`)
      let { progress } = data;

      commit('putVideo', {
        ...video,
        encoding_percentage: progress
      })

      if(data.status == 'job.completed') {
        let video_data = await dispatch('videoById', video.id)

        if(video_data.data.mp4_url) {
          commit('putVideo', video_data.data)
          return 'clear'
        }
      }
    }
  },
  getters: {
    videos: state => state.videos,
    filter: state => state.filter,
    loading: state => state.loading,
    video: state => state.video,
    videoClips: state => {
      if(!state.video || !state.video.clips) return []
      return state.video.clips
    },
    videoTimes: state => {
      if(!state.video || !state.video.times) return []
      return state.video.times
    },
    uploaded: state => state.uploaded,
    show_side_bar: state => state.show_side_bar,
    show_timeline: state => state.show_timeline
  }
}

export default videos