import en from './assets/i18n/en.json';
import fi from './assets/i18n/fi.json';
import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n);

export default new VueI18n({
  locale: localStorage.getItem('tiimi-lang') || 'en',
  messages: {
    en,
    fi
  }
})